import Logger from '@/logger';
const logger = new Logger('rtw:router');

import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router';
import { useStore } from './store';
import * as config from 'config';
import { MutationTypes } from './store/mutations';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./views/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'LogIn',
    component: () => import('./views/LogIn.vue'),
    props: {
      username: '',
      password: '',
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('./views/Maintenance.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const store = useStore();

  // This controls the need for EC authorization
  const accessAuthRequired = !!config.ACCESS_AUTHORIZATION_REQUIRED;
  const authRequiredForRoute = to.matched.some(
    route => route.meta?.requiresAuth
  );

  logger.debug('Access authorization required is', accessAuthRequired);
  logger.debug('Environment is', config.ENV);

  const isDev = process.env.NODE_ENV === 'development';
  if (isDev) {
    logger.debug('.beforeEach: Development mode');
    store.commit(MutationTypes.SetTenantId, 'local');
  }
  if (
    accessAuthRequired &&
    authRequiredForRoute &&
    !store.state.accessGranted
  ) {
    logger.debug('.beforeEach: access not granted');
    next({ name: 'LogIn', query: to.query });
  } else {
    next();
  }
});

export default router;
