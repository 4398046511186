import { GetterTree } from 'vuex';
import { utcToZonedTime } from 'date-fns-tz';

import {
  Airport,
  City,
  Country,
  FlightTimes,
  Maybe,
  SeatClass,
  unMaybe,
} from '@/api/service';
import { State } from '..';

import { carrierCodeToName } from '@/assets/carriers/airlines';

const continentData = [
  {
    countryCodes:
      'AD,AE,AL,AM,AT,AZ,BA,BE,BG,BH,BY,CH,CY,CZ,DE,DK,DZ,EE,EG,ES,FI,FO,FR,GB,GE,GI,GR,HR,HU,IE,IL,IQ,IR,IS,IT,JO,KG,KW,KZ,LB,LI,LT,LU,LV,LY,MA,MC,MD,ME,MK,MT,NL,NO,OM,PL,PS,PT,QA,RO,RS,RU,SA,SD,SE,SH,SI,SJ,SK,SM,SY,TJ,TM,TN,TR,UA,UZ,YE',
    name: 'Europe-Middle East',
    continentCode: 'EME',
  },
  {
    countryCodes:
      'AF,BD,BN,BT,CC,CN,CX,FM,GU,HK,ID,IN,IO,JP,KH,KR,LA,LK,MH,MM,MN,MO,MP,MV,MY,NP,PH,PK,PW,SG,TH,TL,TW,VN',
    name: 'Asia',
    continentCode: 'ASI',
  },
  {
    countryCodes:
      'AG,AI,AN,AW,BB,BM,BQ,BS,BZ,CA,CR,CU,CW,DM,DO,GD,GL,GP,GT,HN,HT,JM,KN,KY,LC,MQ,MS,MX,NI,PA,PM,PR,SV,SX,TC,TT,UM,US,VC,VG,VI',
    name: 'North America',
    continentCode: 'AMN',
  },
  {
    countryCodes:
      'AO,BF,BI,BJ,BW,CD,CF,CG,CI,CM,CV,DJ,ER,ET,GA,GH,GM,GN,GQ,GW,KE,KM,LR,LS,MG,ML,MR,MU,MW,MZ,NA,NE,NG,RE,RW,SC,SL,SN,SO,ST,SZ,TD,TG,TZ,UG,YT,ZA,ZM,ZW',
    name: 'Africa',
    continentCode: 'AFR',
  },
  {
    countryCodes: 'AR,BO,BR,CL,CO,EC,FK,GF,GY,PE,PY,SR,UY,VE',
    name: 'South America',
    continentCode: 'AMS',
  },
  {
    countryCodes: 'AS,AU,CK,FJ,KI,NC,NF,NR,NU,NZ,PF,PG,SB,TK,TO,TV,VU,WF,WS',
    name: 'South West Pacific',
    continentCode: 'ANZ',
  },
];

export type seatClassType = {
  label: string;
  value: string;
};

// WARNING: this is highly dependent on graphql API
export const SEAT_CLASS: seatClassType[] = [
  {
    label: 'Economy Class',
    value: SeatClass.SeatClassEconomy,
  },
  {
    label: 'Business Class',
    value: SeatClass.SeatClassBusiness,
  },
  {
    label: 'First Class',
    value: SeatClass.SeatClassFirst,
  },
];

const continentColors: { [key: string]: string } = {
  AMS: '#9370DB',
  AMN: '#df84f5',
  ANZ: '#FF7F50',
  ASI: '#FFA500',
  AFR: '#2E8B57',
  EME: '#32CD32',
};

export interface Getters {
  countryContinentColor: () => (city: City) => string;
  countryForCountryCode: (
    state: State
  ) => (countryCode: string) => string | undefined;
  nameForCarrierCode: () => (code: string | undefined) => string | undefined;
  logoUriForCarrierCode: () => (code: string | undefined) => string | undefined;
  findSeatClassByLabel: () => (
    label: string | undefined
  ) => seatClassType | undefined;
  findSeatClassByValue: () => (
    type: string | undefined
  ) => seatClassType | undefined;
  getReadibleSeatClass: () => (classStr: SeatClass) => string;
  getFlightTime: () => (
    seconds: bigint | null | undefined,
    timeZone: string | null | undefined
  ) => FlightTimes | 'Unknown';
  getAirportString: () => (airport: Airport) => string;
  isAmtHit: (state: State) => boolean;
}

export const getters: GetterTree<State, State> & Getters = {
  countryContinentColor: () => city => {
    const continent = continentData.find(continent => {
      if (city.countryCode != null) {
        return continent.countryCodes.includes(city.countryCode);
      } else {
        return false;
      }
    });
    return continent ? continentColors[continent.continentCode] : '#aaa';
  },

  countryForCountryCode: state => countryCode => {
    const country:
      | Maybe<Country>
      | undefined = state?.geographyData?.countries?.find(country => {
      return country?.code === countryCode;
    });
    return country && unMaybe(country) ? unMaybe(country?.name) : undefined;
  },

  nameForCarrierCode: () => code => {
    if (code === undefined) return undefined;
    return carrierCodeToName[code];
  },

  logoUriForCarrierCode: () => code => {
    if (code === undefined) return undefined;
    const airline = carrierCodeToName[code];
    if (airline) {
      const urlAirline = airline.toLowerCase().replace(/ /g, '-');
      return `assets/carriers/logos/${urlAirline}`;
    } else {
      return undefined;
    }
  },

  findSeatClassByLabel: () => (
    sClass: string | undefined
  ): seatClassType | undefined => {
    return SEAT_CLASS.find((x: seatClassType) => x.label === sClass);
  },

  findSeatClassByValue: () => (
    sClass: string | undefined
  ): seatClassType | undefined => {
    return SEAT_CLASS.find((x: seatClassType) => x.value === sClass);
  },

  // based on the assumption that
  getReadibleSeatClass: () => (sClass: SeatClass): string => {
    const last = sClass.split('_').pop();
    return last && last.length > 1
      ? last[0].toUpperCase() + last.slice(1).toLowerCase()
      : '';
  },

  getFlightTime: () => (
    seconds: bigint | null | undefined,
    timeZone: string | null | undefined
  ): FlightTimes | 'Unknown' => {
    if (!seconds || !timeZone) return 'Unknown';

    const localDate = utcToZonedTime(Number(seconds) * 1000, timeZone);
    const hour = localDate.getHours();

    if (hour >= 0 && hour < 5) {
      return FlightTimes.EarlyMorning;
    } else if (hour >= 5 && hour < 12) {
      return FlightTimes.Morning;
    } else if (hour >= 12 && hour < 18) {
      return FlightTimes.Afternoon;
    }
    return FlightTimes.Evening;
  },
  getAirportString: () => (airport: Airport): string => {
    return `${airport.name} (${airport.code})`;
  },

  isAmtHit: state =>
    state.workerId !== undefined &&
    state.assignmentId !== undefined &&
    state.taskId !== undefined &&
    state.hitId !== undefined &&
    state.turkSubmitTo !== undefined,
};
