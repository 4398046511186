import Logger from '@/logger';
const logger = new Logger('rtw:user-actions');

import { format } from 'date-fns';

import { City, Concept, Formula } from '@/api/service';
import { dateStr, dateFormat } from '@/format';
import {
  conceptToString,
  formulaBuilder,
  formulaToString,
  literalDateStringBuilder,
  literalStringBuilder,
  literalStringSetBuilder,
  propertyValueBuilder,
  sendActionBuilder,
} from '@/api/kr';

//
// Helpers

// Create a date, but allow undefined
export function createDate(date: string | number | undefined | Date) {
  if (date === undefined) {
    return undefined;
  } else {
    return new Date(date);
  }
}

//
// Actions
//

export function setStartDate(date: Date | undefined) {
  if (date === undefined) {
    logger.error('.setStartDate : called with undefined arguments');
    return undefined;
  }
  return sendActionBuilder(
    `Begin trip on ${format(date, dateFormat.full)}.`,
    formulaBuilder('rtwAction/setStartDate', [
      propertyValueBuilder('localDate', literalDateStringBuilder(date)),
    ])
  );
}

export function setStartDateRange(
  start: Date | undefined,
  end: Date | undefined
) {
  if (start === undefined || end === undefined) {
    logger.error('.setStartDateRange : called with undefined arguments');
    return undefined;
  }
  return sendActionBuilder(
    'Begin trip between ' +
      dateStr(start, dateFormat.full) +
      ' and ' +
      dateStr(end, dateFormat.full) +
      '.',
    formulaBuilder('rtwAction/setStartDateRange', [
      propertyValueBuilder('startLocalDate', literalDateStringBuilder(start)),
      propertyValueBuilder('endLocalDate', literalDateStringBuilder(end)),
    ])
  );
}

export function arriveInCityOnDate(
  city: City | undefined,
  visit: Concept | undefined,
  date: Date | undefined
) {
  if (city === undefined || visit === undefined || date === undefined) {
    logger.error('.arriveInCityOnDate: called with undefined arguments');
    return undefined;
  }
  const visitAsString = conceptToString(visit);
  if (visitAsString === undefined) {
    logger.error('Could not generate visit string');
    return undefined;
  }
  return sendActionBuilder(
    `Arrive in ${city.name} on ${dateStr(date, dateFormat.full)}`,
    formulaBuilder('rtwAction/requestVisitArrivalDate', [
      propertyValueBuilder('visit', literalStringBuilder(visitAsString)),
      propertyValueBuilder('localDate', literalDateStringBuilder(date)),
    ])
  );
}

export function departFromCityOnDate(
  city: City | undefined,
  visit: Concept | undefined,
  date: Date | undefined
) {
  if (city === undefined || visit === undefined || date === undefined) {
    logger.error('.departFromCityOnDate: called with undefined arguments');
    return undefined;
  }
  const visitAsString = conceptToString(visit);
  if (visitAsString === undefined) {
    logger.error('Could not generate visit string');
    return undefined;
  }
  return sendActionBuilder(
    `Depart from ${city.name} on ${dateStr(date, dateFormat.full)}`,
    formulaBuilder('rtwAction/requestVisitDepartureDate', [
      propertyValueBuilder('visit', literalStringBuilder(visitAsString)),
      propertyValueBuilder('localDate', literalDateStringBuilder(date)),
    ])
  );
}

//
// Remove preferences
//

export function removePreference(
  text: string,
  preference: Formula | undefined
) {
  const prefAsString = formulaToString(preference);
  if (preference && prefAsString) {
    return sendActionBuilder(
      text,
      formulaBuilder('rtwAction/removeUserPreferences', [
        propertyValueBuilder(
          'preferences',
          literalStringSetBuilder([prefAsString])
        ),
      ])
    );
  } else {
    return undefined;
  }
}

export function removeArrivalDatePreference(
  city: City | undefined,
  preference: Formula | undefined
) {
  if (city === undefined || preference === undefined) {
    logger.error(
      '.removeArrivalDatePreference: called with undefined arguments'
    );
    return undefined;
  }
  return removePreference(
    `Remove arrival date constraint for ${city.name} visit`,
    preference
  );
}

export function removeDepartureDatePreference(
  city: City,
  preference: Formula | undefined
) {
  return removePreference(
    `remove departure date constraint for ${city?.name} visit`,
    preference
  );
}

export function removeExactDurationPreference(
  city: City,
  preference: Formula | undefined
) {
  return removePreference(
    `remove exact duration constraint for ${city?.name} visit`,
    preference
  );
}
