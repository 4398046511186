import { GetterTree } from 'vuex';
import { State } from '@/store';
import { Metadata, metadata } from '@/api/service';

export interface Getters {
  endpointWs: (state: State) => string;
  endpointHttp: (state: State) => string;
  accessGranted: (state: State) => boolean;
  loggedIn: (state: State) => boolean;
  ready: (state: State) => boolean;
  metadata: (state: State) => Metadata;
}

export const getters: GetterTree<State, State> & Getters = {
  endpointWs: state => {
    // Grab client
    let protocol = 'wss';
    // TODO: this could be a security issue ... review
    if (state.endpoint.indexOf('localhost:') === 0) {
      protocol = 'ws';
    }
    return `${protocol}://${state.endpoint}/graphql`;
  },

  endpointHttp: state => {
    // Grab client
    let protocol = 'https';
    // TODO: this could be a security issue ... review
    if (state.endpoint.indexOf('localhost:') === 0) {
      protocol = 'http';
    }
    return `${protocol}://${state.endpoint}/graphql`;
  },

  accessGranted: state => state.accessGranted,

  loggedIn: state => state.user.token !== undefined,

  ready: state =>
    state.session !== undefined && state.dialog.messages.length > 0,

  metadata: state => {
    if (state.sessionId) {
      return metadata(
        state.sessionId,
        state.user.userId,
        state.user.tenantId,
        state.user.token
      );
    } else {
      throw Error(
        '.metadata: Can not create metadata call without a valid session'
      );
    }
  },
};
