import { createLogger, transports, format } from 'winston';
import 'setimmediate';
import config from 'config';

export enum LOG_LEVEL {
  ERROR = 'ERROR',
  WARN = 'WARN',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
}

export default class Logger {
  readonly origin;
  readonly logger;

  constructor(origin: string) {
    this.origin = origin;
    const myFormat = format.printf(({ message, level, ...metadata }) => {
      let msg = origin + ` :: ${level} :: ${message}`;
      if (metadata) {
        // @ts-ignore
        msg = metadata['timestamp'] + ' :: ' + msg;
      }
      return msg;
    });
    this.logger = createLogger({
      level: config.LOG_LEVEL.toLowerCase(),
      format: format.combine(
        format.colorize(),
        format.splat(),
        format.timestamp(),
        myFormat
      ),
      defaultMeta: { service: this.origin },
      transports: [
        new transports.Console({ level: config.LOG_LEVEL.toLowerCase() }),
      ],
    });
  }

  public warn(...message: any): void {
    this.logger.warn(message);
  }

  public info(...message: any) {
    this.logger.info(message);
  }

  public error(...message: any) {
    this.logger.error(message);
  }

  debug(...message: any): void {
    this.logger.debug(message);
  }
}
