// Formatting for presentation layer

import { format } from 'date-fns';

export const dateFormat = {
  full: 'MM/dd/yy',
  day: 'E d MMM yyyy',
};

export function dateStr(localDate: Date | null | undefined, formatStr: string) {
  if (localDate != null) {
    return format(localDate, formatStr);
  } else {
    return '-';
  }
}
