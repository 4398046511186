interface TooltipConfig {
  id: string;
  tag: string;
  title: string;
  content: string;
  trigger: string;
  anchor: string;
}

const tooltipConfig: TooltipConfig[] = [];

tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Learn what you can say',
  content: 'You can talk naturally to the agent, as shown in these examples. ',
  trigger: "//*[contains(@class, 'HelpTrigger')]",
  anchor: '.HelpTrigger',
});
tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Explore our pre-built itineraries',
  content:
    'Be inspired by these itineraries created for you by the oneworld team. You can select an adventure that is right for you, or start with one of these and then add a few cities to build your own adventure.',
  trigger: '//div//p[contains(text(), "Ok, I have set your home city")]',
  anchor: '.ShowInspired',
});

// tooltipConfig.push({
//   "id": "3",
//   "title": "Request a reference link to your itinerary",
//   "content": "Provide your email, and give your trip a name. We will send you an email that contains a link to your saved itinerary. You can use this link to make further changes, or to book your trip when you're ready.",
//   "trigger": "//div//p[contains(text(), \"You now have a valid itinerary!\")]",
//   "tag": "save-itinerary",
//   "anchor": "[id*='DialogMenu']"
// });
// tooltipConfig.push({
//   "id": "4",
//   "title": "Rate the app and leave us a comment",
//   "content": "Help us improve our virtual agent by providing feedback. We appreciate both positive and negative feedback.",
//   "trigger": "//div//p[contains(text(),\"Your Itinerary is complete!\")]",
//   "anchor": ".Feedback"
// });
// tooltipConfig.push({
//   "id": "5",
//   "title": "Use undo to take a step back",
//   "content": "Click this button to revert your latest changes. It will cancel your latest request and might bring your itinerary back to a valid state or help you resolve a conflict.",
//   "trigger": "//div//p[contains(text(), \"Ok, I have set your home city\")]",
//   "anchor": ".Undo"
// });

tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Switch to alternative travel',
  content:
    'You can switch to an alternative travel segment by clicking here. This can be helpful when there are no flight options available. For an alternative travel segment, you are responsible for traveling between the two cities.',
  trigger:
    '//div//p[contains(text(), "Would you like to make any changes to your destination cities?")]',
  anchor: '.FlightSegmentPlane',
});
tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Reorder your cities',
  content:
    'Drag and drop cards to change the order of your cities, making sure that your trip flows in a continuous westward or eastward direction from your starting city.',
  trigger:
    '//div//p[contains(text(), "I have found a working city order to meet the rules of the ticket")]',
  anchor: '.cursor-move',
});

tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Check out your early indicative price',
  content:
    'Use the early indicative price to ensure that you are on target with your budget. The price depends on the number of continents you visit, and does not include taxes.',
  trigger:
    '//div//p[contains(text(), "When would you like to begin your trip?")]',
  anchor: '.Pricing',
});
tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Confirm your final price',
  content:
    'Start the check out process using this final price. It is calculated using a base price for the number of continents, taxes and your set preferences.',
  trigger: '//div//button[contains(., "Proceed to booking")]',
  anchor: '.Pricing',
});
tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Consider proactive warnings',
  content:
    'Be aware that some destinations might not be serviced by many flights, so it might not be easy to visit them. Use this information to make informed decisions.',
  trigger: '//div//p[contains(text(), "TODO: THE FIRST PROBLEMATIC CITY")]',
  anchor: '.InputBox',
});
tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Explore flights and flight information',
  content:
    'Review the shortest and most direct route selected for you. Find more details about each flight and any layovers. You can also explore our additional flight options.',
  trigger: "//*[contains(@class, 'MoreFlights')]",
  anchor: '.MoreFlights',
});
tooltipConfig.push({
  id: (tooltipConfig.length + 1).toString(),
  tag: 'contextual-tooltips',
  title: 'Adjust your dates',
  content:
    'Use the calendar to change the duration of any stay in a desired city. Select specific arrival and departure dates. You can also ask the agent to change the dates for you.',
  trigger:
    '//div//p[contains(text(), "Here are my suggested departure and arrival dates")]',
  anchor: '.CalendarIcon',
});

export default tooltipConfig;
