import {
  Concept,
  Formula,
  PropertyValue,
  unMaybe,
  Maybe,
  UserAction,
  dayFormatting,
  unMaybeArray,
} from './service';
import { format } from 'date-fns';
import { Store } from '@/store';
import { removePreference } from '@/api/user-actions';
import { ActionTypes } from '@/store/actions';

//
// KR
//

export interface Visit {
  _formula: Formula;
  id: number;
  city: string; // city code
}

export const userRequestsArrivalDate = 'rtw/userRequestsArrivalDate';
export interface UserRequestsArrivalDate {
  _formula: Formula;
  itinerary: string;
  requestedVisit: Formula;
  date: Date;
  slackDays: number;
}

export const userRequestsDepartureDate = 'rtw/userRequestsDepartureDate';
export interface UserRequestsDepartureDate {
  _formula: Formula;
  itinerary: string;
  requestedVisit: Formula;
  date: Date;
  slackDays: number;
}

export const userRequestsMinDaysInVisit = 'rtw/userRequestsMinDaysInVisit';
export interface UserRequestsMinDaysInVisit {
  _formula: Formula;
  itinerary: string;
  visit: Formula;
  minDays: number;
}

export const userRequestsMaxDaysInVisit = 'rtw/userRequestsMaxDaysInVisit';

export const userRequestsFlight = 'rtw/userRequestsFlight';

export const userRequestExactDaysInVisit = 'rtw/userRequestsExactDaysInVisit';
export interface UserRequestsMaxDaysInVisit {
  _formula: Formula;
  itinerary: string;
  visit: Formula;
  maxDays: number;
}

export interface UserRequestsExactDaysInVisit {
  _formula: Formula;
  itinerary: string;
  visit: Formula;
  exactDays: number;
}

export const userRequestsAlternativeTravel =
  'rtw/userRequestsAlternativeTravel';
export interface UserRequestsAlternativeTravel {
  _formula: Formula;
  itinerary: string;
  visit1: Formula;
  visit2: Formula;
}

//
// Specific Helpers
//

export function toVisitFromConcept(
  concept: Concept | undefined
): Visit | undefined {
  return toVisit(unMaybe(concept?.formula));
}

export function toVisit(formula: Formula | undefined): Visit | undefined {
  if (formula === undefined) {
    return undefined;
  }
  const id = getPropertyNumber('id', formula);
  const city = getPropertyEntity('city', formula);
  if (id && city) {
    return {
      _formula: formula,
      id,
      city,
    };
  } else {
    return undefined;
  }
}

export function getUserRequestsArrivalDate(
  preferences: Array<Formula>
): UserRequestsArrivalDate | undefined {
  const pref = preferences.find(
    p => p.predicate != null && p.predicate === userRequestsArrivalDate
  );
  if (pref !== undefined) {
    const itinerary = getPropertyEntity('itinerary', pref);
    const requestedVisit = getPropertyFormula('requestedVisit', pref);
    const date = getPropertyLocalDate('date', pref);
    const slackDays = getPropertyNumber('slackDays', pref);
    if (itinerary && requestedVisit && date && slackDays) {
      return {
        _formula: pref,
        itinerary,
        requestedVisit,
        date,
        slackDays,
      };
    }
  }
  return undefined;
}

export function getUserRequestsDepartureDate(
  preferences: Array<Formula>
): UserRequestsDepartureDate | undefined {
  const pref = preferences.find(
    p => p.predicate != null && p.predicate === userRequestsDepartureDate
  );
  if (pref !== undefined) {
    const itinerary = getPropertyEntity('itinerary', pref);
    const requestedVisit = getPropertyFormula('requestedVisit', pref);
    const date = getPropertyLocalDate('date', pref);
    const slackDays = getPropertyNumber('slackDays', pref);
    if (itinerary && requestedVisit && date && slackDays) {
      return {
        _formula: pref,
        itinerary,
        requestedVisit,
        date,
        slackDays,
      };
    }
  }
  return undefined;
}

export function isFlightSelectedByUser(
    preferences: Array<Formula>,
    flightId : string,
): boolean {
  let isFlightSelectedByUser = false;
  preferences.forEach(
      p => {
        if (p != null && p.predicate != null && p.predicate === userRequestsFlight) {
          p.propertyValues?.forEach(v => {
            if (v?.property === 'flight') {

              const flightNumbers = flightId?.split('_');
              if (flightNumbers !== undefined) {
                const selectedFlightId = getPropertyEntity(v?.property, p);

                const found = [];
                for (const code of flightNumbers) {
                  const regex = new RegExp(`(${code})`, 'g');
                  const match = selectedFlightId !== undefined ? selectedFlightId.match(regex) : null;
                  if (match !== null) {
                    found.push(...match);
                  }
                }


                isFlightSelectedByUser = found.length === flightNumbers.length;
              }
            }
          });
        }
      }
  );
  return isFlightSelectedByUser;
}
export function getUserRequestsMaxDaysInVisit(
  preferences: Array<Formula>
): UserRequestsMaxDaysInVisit | undefined {
  const pref = preferences.find(
    p => p.predicate != null && p.predicate === userRequestsMaxDaysInVisit
  );
  if (pref !== undefined) {
    const itinerary = getPropertyEntity('itinerary', pref);
    const visit = getPropertyFormula('visit', pref);
    const maxDays = getPropertyNumber('maxDays', pref);
    if (itinerary && visit && maxDays) {
      return {
        _formula: pref,
        itinerary,
        visit,
        maxDays,
      };
    }
  }
  return undefined;
}

export function getUserRequestsExactDaysInVisit(
  preferences: Array<Formula>
): UserRequestsExactDaysInVisit | undefined {
  const pref = preferences.find(
    p => p.predicate != null && p.predicate === userRequestExactDaysInVisit
  );
  if (pref !== undefined) {
    const itinerary = getPropertyEntity('itinerary', pref);
    const visit = getPropertyFormula('visit', pref);
    const exactDays = getPropertyNumber('exactDays', pref);
    if (itinerary && visit && exactDays) {
      return {
        _formula: pref,
        itinerary,
        visit,
        exactDays,
      };
    }
  }
  return undefined;
}

export function getUserRequestsMinDaysInVisit(
  preferences: Array<Formula>
): UserRequestsMinDaysInVisit | undefined {
  const pref = preferences.find(
    p => p.predicate != null && p.predicate === userRequestsMinDaysInVisit
  );
  if (pref !== undefined) {
    const itinerary = getPropertyEntity('itinerary', pref);
    const visit = getPropertyFormula('visit', pref);
    const minDays = getPropertyNumber('minDays', pref);
    if (itinerary && visit && minDays) {
      return {
        _formula: pref,
        itinerary,
        visit,
        minDays,
      };
    }
  }
  return undefined;
}

export function getUserRequestsAlternateTravel(
  preferences: Array<Formula>
): UserRequestsAlternativeTravel | undefined {
  const pref = preferences.find(
    p => p.predicate != null && p.predicate === userRequestsAlternativeTravel
  );
  if (pref !== undefined) {
    const itinerary = getPropertyEntity('itinerary', pref);
    const visit1 = getPropertyFormula('visit1', pref);
    const visit2 = getPropertyFormula('visit2', pref);
    if (itinerary && visit1 && visit2) {
      return {
        _formula: pref,
        itinerary,
        visit1,
        visit2,
      };
    }
  }
  return undefined;
}

// Match home visit predicate
export function isHomeVisit(visit: Maybe<Concept> | undefined): boolean {
  return (
    visit != null &&
    visit.formula != null &&
    visit.formula.predicate != null &&
    visit.formula.predicate === 'rtw/homeVisit_fn'
  );
}

export function getVisitCityCode(
  visit: Maybe<Concept> | undefined
): string | undefined {
  if (visit != null && visit.formula != null) {
    // Pull out city role
    const city = visit.formula.propertyValues?.find(
      p => p?.property === 'city'
    );
    if (city != null) {
      return unMaybe(city.value?.entity?.literal?.s);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

//
// Editable Preference
//

export interface EditablePreference {
  preference: Formula;
  text: string;
}

export function isEditablePreference(preference: Formula) {
  return (
    preference.predicate === userRequestsArrivalDate ||
    preference.predicate === userRequestsDepartureDate ||
    preference.predicate === userRequestsMinDaysInVisit
  );
}

export function editablePreferences(preferences: Formula[], store: Store) {
  return unMaybeArray(preferences)
    .filter(preference => {
      return isEditablePreference(preference);
    })
    .map(preference => {
      const text = verbalize(preference, store);
      return text !== undefined ? { preference, text } : undefined;
    })
    .filter((ep): ep is EditablePreference => ep !== undefined);
}

export async function removeEditablePreference(
  ep: EditablePreference,
  store: Store
) {
  const action = removePreference(
    'Remove requirement to ' + ep.text,
    ep.preference
  );
  if (action != undefined) {
    await store.dispatch(ActionTypes.SendActions, [action]);
  }
}

//
// Verbalize
//

export function verbalize(prop: Formula, store: Store) {
  if (prop.predicate === userRequestsArrivalDate) {
    const pref = getUserRequestsArrivalDate([prop]);
    const city = store.getters.cityFromVisit(pref?.requestedVisit);
    return pref && city
      ? 'I want to arrive in ' +
          city.name +
          ' on ' +
          format(pref.date, dayFormatting)
      : undefined;
  } else if (prop.predicate === userRequestsDepartureDate) {
    const pref = getUserRequestsDepartureDate([prop]);
    const city = store.getters.cityFromVisit(pref?.requestedVisit);
    return pref && city
      ? 'I want to depart from ' +
          city.name +
          ' on ' +
          format(pref.date, dayFormatting)
      : undefined;
  } else if (prop.predicate === userRequestsMinDaysInVisit) {
    const pref = getUserRequestsDepartureDate([prop]);
    const city = store.getters.cityFromVisit(pref?.requestedVisit);
    return pref && city
      ? 'I want to stay in ' +
          city.name +
          ' for ' +
          format(pref.date, dayFormatting) +
          'days'
      : undefined;
  } else {
    return undefined;
  }
}

//
// Generic Helpers
//

function getPropertyValue(
  name: string,
  formula: Formula
): PropertyValue | undefined {
  return unMaybe(
    formula.propertyValues?.find(v => v != null && v.property === name)
  );
}

export function getPropertyString(
  name: string,
  formula: Formula
): string | undefined {
  const property = getPropertyValue(name, formula);
  if (
    property?.value != null &&
    property.value.entity != null &&
    property.value.entity.literal != null &&
    property.value.entity.literal.s != null
  ) {
    return property.value.entity.literal.s;
  } else {
    return undefined;
  }
}

export function getPropertyNumber(
  name: string,
  formula: Formula
): number | undefined {
  const property = getPropertyValue(name, formula);
  if (
    property?.value != null &&
    property.value.entity != null &&
    property.value.entity.literal != null
  ) {
    if (property.value.entity.literal.i != null) {
      return property.value.entity.literal.i;
    } else if (property.value.entity.literal.d != null) {
      return property.value.entity.literal.d;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function getPropertyEntity(
  name: string,
  formula: Formula
): string | undefined {
  const property = getPropertyValue(name, formula);
  if (
    property?.value != null &&
    property.value.entity != null &&
    property.value.entity.basicTerm != null &&
    property.value.entity.basicTerm.name != null
  ) {
    return property.value.entity.basicTerm.name;
  } else {
    return undefined;
  }
}

export function getPropertyFormula(
  name: string,
  formula: Formula
): Formula | undefined {
  const property = getPropertyValue(name, formula);
  if (property?.value != null && property.value.formula != null) {
    return property.value.formula;
  } else {
    return undefined;
  }
}

export function getPropertyLocalDate(
  name: string,
  formula: Formula
): Date | undefined {
  return getDate(getPropertyFormula(name, formula));
}

export function getDate(dateProp: Formula | undefined): Date | undefined {
  if (dateProp === undefined || dateProp.predicate !== 'dt/date') {
    return undefined;
  }
  const month = getPropertyNumber('month', dateProp);
  const dayOfMonth = getPropertyNumber('dayOfMonth', dateProp);
  const year = getPropertyNumber('year', dateProp);
  if (month && dayOfMonth && year) {
    // NOTE: month is zero indexed
    return new Date(year, month - 1, dayOfMonth);
  } else {
    return undefined;
  }
}

//
// Builders
//

export function formulaBuilder(
  predicate: string,
  propertyValues: object[]
): Formula {
  return { predicate, propertyValues };
}

export function formulaConceptBuilder(formula: Formula): Concept {
  return { formula };
}

export function propertyValueBuilder(
  property: string,
  value: Concept
): PropertyValue {
  return { property, value };
}

export function conceptSetBuilder(concepts: Concept[]): Concept {
  return { set: { concepts } };
}

export function literalStringBuilder(value: string): Concept {
  return { entity: { literal: { s: value } } };
}

export function literalStringSetBuilder(values: string[]): Concept {
  return { set: { concepts: values.map(v => literalStringBuilder(v)) } };
}

export function literalIntegerBuilder(value: number): Concept {
  return { entity: { literal: { i: value } } };
}

export function literalBooleanBuilder(value: boolean): Concept {
  return { entity: { literal: { b: value } } };
}

export const dateStringFormatting = 'yyyy-MM-dd';

export function literalDateStringBuilder(date: Date): Concept {
  return { entity: { literal: { s: format(date, dateStringFormatting) } } };
}

export function sendActionBuilder(text: string, action: Formula): UserAction {
  return { text, action };
}

//
// Serialize
//

// Backend requires dates as strings in specific format
export function conceptToString(
  concept: Maybe<Concept> | undefined
): string | undefined {
  if (concept != null) {
    if (concept.formula != null) {
      return (
        concept.formula.predicate +
        '(' +
        concept.formula.propertyValues
          ?.map(p => p?.property + ': ' + conceptToString(p?.value))
          .join(', ') +
        ')'
      );
    } else if (concept.entity != null) {
      if (concept.entity.basicTerm) {
        return unMaybe(concept.entity.basicTerm?.name);
      } else if (concept.entity.literal != null) {
        if (concept.entity.literal.b != null) {
          return concept.entity.literal.b.toString();
        } else if (concept.entity.literal.i != null) {
          return concept.entity.literal.i.toString();
        } else if (concept.entity.literal.s != null) {
          return concept.entity.literal.s;
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    }
  }
  return undefined;
}

export function formulaToString(
  formulaVal: Maybe<Formula> | undefined
): string | undefined {
  if (formulaVal !== null && formulaVal !== undefined) {
    return (
      formulaVal.predicate +
      '(' +
      formulaVal.propertyValues
        ?.map(p => p?.property + ': ' + conceptToString(p?.value))
        .join(', ') +
      ')'
    );
  }
  return undefined;
}
