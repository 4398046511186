<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { RouterView, useRouter } from 'vue-router';
import { useStore } from '@/store';
import { AiEcApiDialogV1PropertyInput } from '@ec/rtw-graphql';
import { MutationTypes } from '@/store/mutations';
import { isEqual } from 'lodash';
import LogRocket from 'logrocket';
import * as config from 'config';

export default defineComponent({
  components: {
    RouterView,
  },
  created: function() {
    if (config.LOG_ROCKET_APP_ID) {
      LogRocket.init(config.LOG_ROCKET_APP_ID, {
        console: {
          isEnabled: true,
        },
        release: process.env.VERSION,
      });
    }
  },
  setup: function() {
    const router = useRouter();

    router.beforeEach(to => {
      if (to.name === 'Home') {
        const params = to.query;
        const originParams = Object.assign({}, params);
        const properties: Array<AiEcApiDialogV1PropertyInput> = [];
        const store = useStore();

        if (params.itineraryName) {
          properties.push({
            name: 'ITINERARY_NAME',
            value: params.itineraryName as string,
          });
        }
        delete params.itineraryName;
        if (params.payload) {
          properties.push({ name: 'PAYLOAD', value: params.payload as string });
          properties.push({ name: 'PAYLOAD_TYPE', value: 'OW' });
        }
        delete params.payload;
        if (params.payloadEC) {
          properties.push({
            name: 'PAYLOAD',
            value: params.payloadEC as string,
          });
          properties.push({ name: 'PAYLOAD_TYPE', value: 'EC' });
        }
        delete params.payloadEC;
        if (params.travellers) {
          properties.push({
            name: 'TRAVELERS',
            value: params.travellers as string,
          });
        }
        delete params.travellers;
        if (params.class) {
          properties.push({
            name: 'SEAT_CLASS',
            value: params.class as string,
          });
        }
        delete params.class;
        if (params.preferredAirline) {
          properties.push({
            name: 'PREFERRED_CARRIER',
            value: params.preferredAirline as string,
          });
        }
        if (params.rtwUniqueSessionToken) {
          properties.push({
            name: 'ZEN3_SESSION_TOKEN',
            value: params.rtwUniqueSessionToken as string,
          });
        }
        delete params.rtwUniqueSessionToken;
        if (params.refId) {
          store.commit(MutationTypes.SetSessionId, params.refId as string);
        }
        delete params.refId;
        delete params.preferredAirline;
        delete params.ecItineraryKey;

        if (properties.length > 0) {
          store.commit(MutationTypes.SetBotProperties, properties);
        }
        if (!isEqual(params, originParams)) {
          return { name: 'Home', query: params };
        }
      }
    });
  },
});
</script>
