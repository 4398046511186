// import Debug from "debug";
// const debug = logger.debug("rtw:store");
// const error = logger.debug("error");

import { Client } from 'graphql-ws';
import { MutationTree } from 'vuex';

import {
  DialogBot,
  Itinerary,
  Maybe,
  PriceSummary,
  Message,
  SystemMessage,
  UserMessage,
  TravelInfo,
  GeographyResponse,
  Completion,
  Session,
  DialogStatus,
  SessionStatus,
  Tooltip,
  City,
  Choice,
  Money,
  Timestamp,
  Flight,
  Leg,
  Segment,
  Interim,
  LocalDateTime,
  CompletionFocus,
  FlightSegment,
} from '@/api/service';
import {
  INITIAL_RETRY_WAIT_MS,
  RETRY_WAIT_BACKOFF,
  State,
  User,
  Zen3LoginUser,
  Zen3SocialLogin,
} from '.';
import { LDClient } from 'launchdarkly-js-client-sdk';
import { AiEcApiDialogV1PropertyInput } from '@ec/rtw-graphql/dist/index';

export enum MutationTypes {
  SetBotProperties = 'SetBotProperties',
  SetDebug = 'SetDebug',
  SetLDClient = 'SetLDClient',
  SetClient = 'SetClient',
  SetSessionId = 'SetSessionId',
  SetSession = 'SetSession',
  SetSessionStatus = 'SetSessionStatus',
  SetErrorMessage = 'SetErrorMessage',
  AddWarning = 'AddWarning',
  AddError = 'AddError',
  SetAccessGranted = 'SetAccessGranted',
  SetTenantId = 'SetTenantId',
  SetUserFromZen3LoginUser = 'SetUserFromZen3LoginUser',
  SetUserFromZen3SocialLogin = 'SetUserFromZen3SocialLogin',
  ClearUser = 'ClearUser',
  SetIsConnected = 'SetIsConnected',
  SetHandleDisconnect = 'SetHandleDisconnect',
  ClearRetry = 'ClearRetry',
  ClearNextRetry = 'ClearNextRetry',
  NextRetry = 'NextRetry',
  DeleteUnsubscriber = 'DeleteUnsubscriber',
  SetUnsubscriber = 'SetUnsubscriber',
  SetDialog = 'SetDialog',
  PushUserMessage = 'PushUserMessage',
  SetDialogBot = 'SetDialogBot',
  SetDialogStatus = 'SetDialogStatus',
  SetDialogInputBoxText = 'SetDialogInputBoxText',
  SetDialogInputBoxLastInteraction = 'SetDialogInputBoxLastInteraction',
  SetDialogShowHelp = 'SetDialogShowHelp',
  SetWaitingOnDialogUpdateAfterSend = 'SetWaitingOnDialogUpdateAfterSend',
  ToggleChoice = 'ToggleChoice',
  ClearSelectedChoices = 'ClearSelectedChoices',
  SetCompletions = 'SetCompletions',
  ClearItinerary = 'ClearItinerary',
  SetItinerary = 'SetItinerary',
  SetShowItinerary = 'SetShowItinerary',
  SetHadDates = 'SetHadDates',
  SetGeographyData = 'SetGeographyData',
  ClearOrigin = 'ClearOrigin',
  SetEnd = 'SetEnd',
  ClearPriceSummary = 'ClearPriceSummary',
  SetPriceSummary = 'SetPriceSummary',
  ClearTravelInfo = 'ClearTravelInfo',
  SetTravelInfo = 'SetTravelInfo',
  SetTaskId = 'SetTaskId',
  SetWorkerId = `SetWorkerId`,
  SetAssignmentId = 'SetAssignmentId',
  SetHitId = 'SetHitId',
  SetTurkSubmitTo = 'SetTurkSubmitTo',
  SetSavedItinerary = 'SetSavedItinerary',
  SetTooltips = 'SetTooltips',
  SetMatchingTooltips = 'SetMatchingTooltips',
  SetShowingOnboarding = 'SetShowingOnboarding',
  SetShowInspiration = 'SetShowInspiration',
  SetInspirationItineraryCities = 'SetInspirationItineraryCities',
  SetShowItineraryList = 'SetShowItineraryList',
  SetItineraryList = 'SetItineraryList',
  RemoveItinerary = 'RemoveItinerary',
  SetCurrentStopOverSegment = 'SetCurrentStopOverSegment',
}

export type Mutations<S = State> = {
  [MutationTypes.SetBotProperties](
    state: S,
    properties: Array<AiEcApiDialogV1PropertyInput>
  ): void;
  [MutationTypes.SetDebug](state: S, debug: boolean): void;
  [MutationTypes.SetClient](state: S, client: Client): void;
  [MutationTypes.SetLDClient](state: S, client: LDClient): void;
  [MutationTypes.SetSessionId](state: S, sessionId?: string): void;
  [MutationTypes.SetSession](state: S, session?: Maybe<Session>): void;
  [MutationTypes.SetSessionStatus](
    state: S,
    status: Maybe<SessionStatus>
  ): void;
  [MutationTypes.SetErrorMessage](state: S, message?: string): void;
  [MutationTypes.AddWarning](state: S, message: string): void;
  [MutationTypes.AddError](state: S, message: string): void;
  [MutationTypes.SetTenantId](state: S, tenantId: string): void;
  [MutationTypes.SetUserFromZen3LoginUser](
    state: S,
    { loginUser, userKeyId }: { loginUser: Zen3LoginUser; userKeyId: string }
  ): void;
  [MutationTypes.SetUserFromZen3SocialLogin](
    state: S,
    {
      socialLogin,
      userKeyId,
    }: { socialLogin: Zen3SocialLogin; userKeyId: string }
  ): void;
  [MutationTypes.ClearUser](state: S): void;
  [MutationTypes.SetIsConnected](state: S, isConnected: boolean): void;
  [MutationTypes.SetHandleDisconnect](
    state: S,
    handleDisconnect: boolean
  ): void;
  [MutationTypes.ClearRetry](state: S): void;
  [MutationTypes.ClearNextRetry](state: S, nextRetry?: Date): void;
  [MutationTypes.NextRetry](state: S): void;
  [MutationTypes.DeleteUnsubscriber](state: S, key: string): void;
  [MutationTypes.SetUnsubscriber](
    state: S,
    { key, unsubscriber }: { key: string; unsubscriber: () => void }
  ): void;
  [MutationTypes.SetDialogBot](state: S, bot?: Maybe<DialogBot>): void;
  [MutationTypes.SetDialog](state: S, messages: Maybe<Message>[]): void;
  [MutationTypes.PushUserMessage](state: S, userMessage: UserMessage): void;
  [MutationTypes.SetDialogStatus](state: S, status: Maybe<DialogStatus>): void;
  [MutationTypes.SetDialogInputBoxText](state: S, text: string): void;
  [MutationTypes.SetDialogInputBoxLastInteraction](
    state: S,
    datetime: Date
  ): void;
  [MutationTypes.SetDialogShowHelp](state: S, show: boolean): void;
  [MutationTypes.SetWaitingOnDialogUpdateAfterSend](
    state: S,
    show: boolean
  ): void;
  [MutationTypes.ToggleChoice](state: S, choice: Choice): void;
  [MutationTypes.ClearSelectedChoices](state: S): void;
  [MutationTypes.SetCompletions](state: S, messages: Maybe<Completion>[]): void;
  [MutationTypes.ClearItinerary](state: S): void;
  [MutationTypes.SetItinerary](state: S, itinerary: Itinerary): void;
  [MutationTypes.SetShowItinerary](state: S, show: boolean): void;
  [MutationTypes.SetHadDates](state: S, hadDates: boolean): void;
  [MutationTypes.SetGeographyData](
    state: S,
    geographyData: GeographyResponse
  ): void;
  [MutationTypes.ClearPriceSummary](state: S): void;
  [MutationTypes.SetPriceSummary](state: S, priceSummary: PriceSummary): void;
  [MutationTypes.ClearTravelInfo](state: S): void;
  [MutationTypes.SetTravelInfo](state: S, travelInfo: Maybe<TravelInfo>): void;
  [MutationTypes.SetTaskId](state: S, taskId: string): void;
  [MutationTypes.SetWorkerId](state: S, workerId: string): void;
  [MutationTypes.SetAssignmentId](state: S, assignmentId: string): void;
  [MutationTypes.SetHitId](state: S, hitId: string): void;
  [MutationTypes.SetTurkSubmitTo](state: S, turkSubmitTo: string): void;
  [MutationTypes.SetAccessGranted](state: S, isAuthenticated: boolean): void;
  [MutationTypes.SetSavedItinerary](state: S, linkId: string): void;
  [MutationTypes.SetTooltips](state: S, tooltips: Tooltip[]): void;
  [MutationTypes.SetMatchingTooltips](state: S, tooltips: Tooltip[]): void;
  [MutationTypes.SetShowingOnboarding](state: S, isShowing: boolean): void;
  [MutationTypes.SetShowInspiration](state: S, isShowing: boolean): void;
  [MutationTypes.SetInspirationItineraryCities](state: S, cities: City[]): void;
  [MutationTypes.SetShowItineraryList](state: S, show: boolean): void;
  [MutationTypes.SetItineraryList](state: S, itineraries: unknown[]): void;
  [MutationTypes.RemoveItinerary](state: S, itinerary: unknown): void;
  [MutationTypes.SetCurrentStopOverSegment](state: S, segment: Segment): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetBotProperties](state, properties) {
    state.dialog.properties = properties;
  },
  [MutationTypes.SetDebug](state, debug) {
    state.debug = debug;
  },
  [MutationTypes.SetClient](state, client) {
    state.client = client;
  },
  [MutationTypes.SetLDClient](state, client) {
    state.ldClient = client;
  },
  [MutationTypes.SetSessionId](state, sessionId) {
    state.sessionId = sessionId;
  },
  [MutationTypes.SetSession](state, session) {
    state.session = session != null ? session : undefined;
  },
  [MutationTypes.SetSessionStatus](state, status) {
    state.sessionStatus = status != null ? status : undefined;
  },
  [MutationTypes.SetErrorMessage](state, message) {
    state.errorMessage = message;
  },
  [MutationTypes.AddWarning](state, message) {
    // Done to create a reference so we can watch the difference -- seems shitty
    const copiedArray = state.warnings.slice(0);
    copiedArray.push(message);
    state.warnings = copiedArray;
  },
  [MutationTypes.AddError](state, message) {
    // Done to create a reference so we can watch the difference -- seems shitty
    const copiedArray = state.errors.slice(0);
    copiedArray.push(message);
    state.errors = copiedArray;
  },
  [MutationTypes.SetAccessGranted](state, accessGranted) {
    state.accessGranted = accessGranted;
  },
  [MutationTypes.SetTenantId](state, tenantId) {
    state.user.tenantId = tenantId;
  },
  [MutationTypes.SetUserFromZen3LoginUser](state, { loginUser, userKeyId }) {
    state.user.zen3 = {
      loginUser,
      userId: loginUser.UserId,
      userKeyId,
    };
    // Assign all required values
    // Special encoding in userId for backend
    state.user.userId = userIdEncode(userKeyId, loginUser.UserId);
    state.user.token = loginUser.Token;
    state.user.firstName = loginUser.FirstName;
    state.user.lastName = loginUser.LastName;
    state.ldClient?.identify(ldClientUser(state.user, state.sessionId));
  },
  [MutationTypes.SetUserFromZen3SocialLogin](
    state,
    { socialLogin, userKeyId }
  ) {
    state.user.zen3 = {
      socialLogin,
      userId: socialLogin.UserId,
      userKeyId,
    };
    // Assign all required values
    // Special encoding in userId for backend
    state.user.userId = userIdEncode(userKeyId, socialLogin.UserId);
    state.user.token = socialLogin.SessionToken;
    state.user.firstName = socialLogin.FirstName;
    state.user.lastName = socialLogin.LastName;
    state.ldClient?.identify(ldClientUser(state.user, state.sessionId));
  },
  [MutationTypes.ClearUser](state) {
    state.user.userId = 'anonymous';
    state.user.tenantId = 'rtw';
    state.user.token = undefined;
    state.user.firstName = undefined;
    state.user.lastName = undefined;
    state.user.zen3 = undefined;
  },
  [MutationTypes.SetIsConnected](state, isConnected) {
    state.isConnected = isConnected;
  },
  [MutationTypes.SetHandleDisconnect](state, handleDisconnect) {
    state.handleDisconnect = handleDisconnect;
  },
  [MutationTypes.ClearRetry](state) {
    state.retryWaitMs = INITIAL_RETRY_WAIT_MS;
    state.nextRetry = undefined;
    state.retryAttempts = 0;
  },
  [MutationTypes.ClearNextRetry](state) {
    state.nextRetry = undefined;
  },
  [MutationTypes.NextRetry](state) {
    state.retryWaitMs = state.retryWaitMs * RETRY_WAIT_BACKOFF;
    state.nextRetry = new Date(Date.now() + state.retryWaitMs);
    state.retryAttempts = state.retryAttempts + 1;
  },
  [MutationTypes.SetDialogBot](state, bot) {
    state.dialog.bot = bot == null ? undefined : bot;
  },
  [MutationTypes.DeleteUnsubscriber](state, key) {
    delete state.unsubscribers[key];
  },
  [MutationTypes.SetUnsubscriber](state, { key, unsubscriber }) {
    state.unsubscribers[key] = unsubscriber;
  },

  //
  // Dialog
  //
  [MutationTypes.SetDialog](state, messages) {
    // FIX PROTO DEFAULTS :(
    messages.forEach(m => protoDeNullMessage(m));

    // Filter and assign
    state.dialog.messages = messages
      .filter(
        message =>
          message != null && (message?.userMessage || message?.systemMessage)
      )
      .map(message => {
        if (message?.systemMessage) return message.systemMessage;
        else return message?.userMessage;
      }) as Array<UserMessage | SystemMessage>; // TODO: fix this
  },
  [MutationTypes.PushUserMessage](state, userMessage) {
    state.dialog.messages.push(userMessage);
  },
  [MutationTypes.SetDialogStatus](state, status) {
    state.dialog.status = status != null ? status : undefined;
  },
  [MutationTypes.SetCompletions](state, completions) {
    // FIX PROTO DEFAULTS :(
    completions?.filter(s => protoDeNullCompletion(s));
    // Assign
    state.dialog.inputBox.completions = completions.filter(
      c => c != null
    ) as Completion[];
  },
  [MutationTypes.SetDialogInputBoxText](state, text) {
    state.dialog.inputBox.text = text;
  },
  [MutationTypes.SetDialogInputBoxLastInteraction](state, lastInteraction) {
    state.dialog.inputBox.lastInteraction = lastInteraction;
  },
  [MutationTypes.SetDialogShowHelp](state, show) {
    state.dialog.showHelp = show;
  },
  [MutationTypes.SetWaitingOnDialogUpdateAfterSend](state, waiting) {
    state.dialog.waitingOnDialogUpdateAfterSend = waiting;
  },
  [MutationTypes.ClearSelectedChoices](state) {
    state.dialog.inputBox.selectedChoices = [];
  },
  [MutationTypes.ToggleChoice](state, choice) {
    if (state.dialog.inputBox.selectedChoices.includes(choice)) {
      state.dialog.inputBox.selectedChoices.splice(
        state.dialog.inputBox.selectedChoices.indexOf(choice),
        1
      );
    } else {
      state.dialog.inputBox.selectedChoices.push(choice);
    }
  },

  [MutationTypes.SetGeographyData](state, geographyData) {
    // FIX PROTO DEFAULTS :(
    geographyData?.cities?.forEach(c => protoDeNullCity(c));
    // Assign
    state.geographyData = geographyData;
  },
  [MutationTypes.ClearItinerary](state) {
    state.itinerary = undefined;
  },
  [MutationTypes.SetItinerary](state, itinerary) {
    // FIX PROTO DEFAULTS :(
    protoDeNullItinerary(itinerary);
    // Assign
    state.itinerary = itinerary;
  },
  [MutationTypes.SetShowItinerary](state, show) {
    state.showItinerary = show;
  },
  [MutationTypes.SetHadDates](state, hadDates) {
    state.hadDates = hadDates;
  },
  [MutationTypes.SetPriceSummary](state, priceSummary) {
    // FIX PROTO DEFAULTS :(
    protoDeNullMoney(priceSummary?.price?.total);
    protoDeNullMoney(priceSummary?.price?.base);
    protoDeNullMoney(priceSummary?.price?.tax);
    // Assign
    state.priceSummary = priceSummary;
  },
  [MutationTypes.ClearPriceSummary](state) {
    state.priceSummary = undefined;
  },
  [MutationTypes.SetTravelInfo](state, travelInfo) {
    // FIX PROTO DEFAULTS :(
    if (travelInfo?.numAdults === null) travelInfo.numAdults = 0;
    if (travelInfo?.numChildren === null) travelInfo.numChildren = 0;
    if (travelInfo?.numInfants === null) travelInfo.numInfants = 0;
    // Assign
    state.travelInfo = travelInfo != null ? travelInfo : undefined;
  },
  [MutationTypes.ClearTravelInfo](state) {
    state.travelInfo = undefined;
  },

  //
  // AMT
  //
  [MutationTypes.SetTaskId](state, taskId) {
    state.taskId = taskId;
  },
  [MutationTypes.SetWorkerId](state, workerId) {
    state.workerId = workerId;
  },
  [MutationTypes.SetAssignmentId](state, assignmentId) {
    state.assignmentId = assignmentId;
  },
  [MutationTypes.SetHitId](state, hitId) {
    state.hitId = hitId;
  },
  [MutationTypes.SetTurkSubmitTo](state, turkSubmitTo) {
    state.turkSubmitTo = turkSubmitTo;
  },
  [MutationTypes.SetSavedItinerary](state, linkId) {
    state.linkId = linkId;
  },
  [MutationTypes.SetTooltips](state, tooltips) {
    state.allTooltips = tooltips;
  },
  [MutationTypes.SetMatchingTooltips](state, tooltips) {
    state.matchingTooltips = tooltips;
  },
  [MutationTypes.SetShowingOnboarding](state, isShowing) {
    state.showingOnboarding = isShowing;
  },
  [MutationTypes.SetShowInspiration](state, isShowing) {
    state.showInspiration = isShowing;
  },
  [MutationTypes.SetInspirationItineraryCities](state, cities) {
    state.inspirationItineraryCities = cities;
  },
  [MutationTypes.SetShowItineraryList](state, show) {
    state.showItineraryList = show;
  },
  [MutationTypes.SetItineraryList](state, itineraries) {
    state.itineraryList = itineraries;
  },
  [MutationTypes.RemoveItinerary](state, itinerary) {
    state.itineraryList = state.itineraryList.filter(i => i != itinerary);
  },
  [MutationTypes.SetCurrentStopOverSegment](state, segment) {
    state.currentStopOverSegment = segment;
  },
};

function protoDeNullMessage(message: Message | null | undefined) {
  if (message != null) {
    if (message.systemMessage != null) {
      protoDeNullSystemMessage(message.systemMessage);
    }
  }
}

function protoDeNullSystemMessage(
  systemMessage: SystemMessage | null | undefined
) {
  if (systemMessage != null) {
    if (
      systemMessage.multiChoice != null &&
      systemMessage.multiChoice.choices != null
    ) {
      // Fix choices index default - proto sends index === 0 as null
      systemMessage.multiChoice.choices.forEach(c => protoDeNullChoice(c));
    }
  }
}

function protoDeNullChoice(choice: Choice | null | undefined) {
  if (choice != null) {
    if (choice.index === null) choice.index = 0;
    if (choice.isMutuallyExclusive === null) choice.isMutuallyExclusive = false;
  }
}

function protoDeNullItinerary(itinerary: Itinerary | null | undefined) {
  if (itinerary != null) {
    itinerary.segments?.forEach(s => protoDeNullSegment(s));
    itinerary.interims?.forEach(i => protoDeNullInterim(i));
    if (itinerary.arrivalPreferences === null)
      itinerary.arrivalPreferences = [];
    if (itinerary.departurePreferences === null)
      itinerary.departurePreferences = [];
  }
}

function protoDeNullSegment(segment: Segment | null | undefined) {
  if (segment != null) {
    if (segment?.sequence === null) segment.sequence = 0;
    protoDeNullCity(segment.arrival);
    protoDeNullTimestamp(segment.arrivalTime);
    protoDeNullCity(segment.departure);
    protoDeNullTimestamp(segment.departureTime);
    protoDeNullFlightSegment(segment.flightSegment);
  }
}

function protoDeNullFlightSegment(
  flightSegment: FlightSegment | null | undefined
) {
  if (flightSegment != null) {
    flightSegment.flightOptions?.forEach(fo => protoDeNullFlight(fo));
    protoDeNullFlight(flightSegment.selectedFlight);
  }
}

function protoDeNullInterim(interim: Interim | null | undefined) {
  if (interim != null) {
    protoDeNullCity(interim.city);
    protoDeNullDateTime(interim.arrivalLocalDateTime);
    protoDeNullDateTime(interim.departureLocalDateTime);
    if (interim.preferences === null) interim.preferences = [];
  }
}

function protoDeNullCity(city: City | null | undefined) {
  if (city != null) {
    if (city.hasAmbiguousName === null) city.hasAmbiguousName = false;
  }
}

function protoDeNullFlight(flight: Flight | null | undefined) {
  if (flight != null) {
    protoDeNullCity(flight.arrivalCity);
    protoDeNullTimestamp(flight.arrivalTime);
    protoDeNullCity(flight.departureCity);
    protoDeNullTimestamp(flight.departureTime);
    if (flight.legs != null) {
      flight.legs.forEach(l => protoDeNullLeg(l));
    }
  }
}

function protoDeNullLeg(leg: Leg | null | undefined) {
  if (leg != null) {
    protoDeNullCity(leg.arrivalCity);
    protoDeNullTimestamp(leg.arrivalTime);
    protoDeNullCity(leg.departureCity);
    protoDeNullTimestamp(leg.departureTime);
  }
}

function protoDeNullMoney(money: Money | null | undefined) {
  if (money != null) {
    if (money.units === null) money.units = 0;
    if (money.nanos === null) money.nanos = 0;
  }
}

function protoDeNullTimestamp(timestamp: Timestamp | null | undefined) {
  if (timestamp != null) {
    if (timestamp.seconds === null) timestamp.seconds = 0;
    if (timestamp.nanos === null) timestamp.nanos = 0;
  }
}

function protoDeNullDateTime(dateTime: LocalDateTime | null | undefined) {
  if (dateTime != null) {
    if (dateTime.hours === null) dateTime.hours = 0;
    if (dateTime.minutes === null) dateTime.minutes = 0;
    if (dateTime.seconds === null) dateTime.seconds = 0;
  }
}

function protoDeNullCompletion(completion: Completion | null | undefined) {
  if (completion != null) {
    completion.foci?.forEach(f => protoDeNullCompletionFocus(f));
  }
}

function protoDeNullCompletionFocus(focus: CompletionFocus | null | undefined) {
  if (focus != null) {
    if (focus.span?.begin === null) focus.span.begin = 0;
    if (focus.span?.end === null) focus.span.end = 0;
  }
}

function userIdEncode(userKeyId: string, userName: string) {
  return 'userKeyId=' + userKeyId + ',userName=' + userName;
}

function ldClientUser(user: User, sessionId?: string) {
  return {
    key: user.userId,
    uniqueId: sessionId,
    tenantId: user.tenantId,
  };
}
