import Logger from '@/logger';
const logger = new Logger('rtw:store');

import { State } from '@/store';
import { Getters as GlobalGetters } from '@/store/getters';

import {
  Choice,
  isMultiChoice,
  isSystemMessage,
  SystemIntentType,
  SystemMessage,
  unMaybeArray,
  UserMessage,
} from '@/api/service';
import { GetterTree } from 'vuex';

export interface Getters {
  lastMessage: (state: State) => SystemMessage | UserMessage | undefined;
  systemMessages: (state: State) => SystemMessage[];
  lastSystemMessage: (state: State) => SystemMessage | undefined;
  lastRequestSystemMessage: (state: State) => SystemMessage | undefined;
  lastMessageIsRequestSystemMessage(
    state: State,
    getters: GlobalGetters
  ): boolean;
  lastSystemMessageExpectsLocation(
    state: State,
    getters: GlobalGetters
  ): boolean;
  lastSystemMessageExpectsDate(state: State, getters: GlobalGetters): boolean;
  lastSystemMessageExpectsDateRange(
    state: State,
    getters: GlobalGetters
  ): boolean;
  systemMessagesSinceLastUserMessage(state: State): SystemMessage[];
  systemMessagesGrouped(state: State): SystemMessage[][];
  isLastSystemMessageInGroup: (
    state: State,
    getters: GlobalGetters
  ) => (systemMessage: SystemMessage) => boolean;
  hasRepeatedSubtext: (
    state: State,
    getters: GlobalGetters
  ) => (systemMessage: SystemMessage) => boolean;
  isFollowedByUserMessage: (
    state: State,
    getters: GlobalGetters
  ) => (systemMessage: SystemMessage) => boolean;
  haveActiveRequestSystemMessage(state: State, getters: GlobalGetters): boolean;
  haveFatalError(state: State): boolean;
  requireMultiChoiceResponse(state: State, getters: GlobalGetters): boolean;
  isTextFeedBackPrompt(state: State, getter: GlobalGetters): boolean,
  multiChoiceChoices(state: State, getters: GlobalGetters): Choice[];
  requireDialogResponse(state: State, getters: GlobalGetters): boolean;
  disabledTextEntry(state: State, getters: GlobalGetters): boolean;
  disabledSend(state: State, getters: GlobalGetters): boolean;
  nextSystemMessage: (
    state: State
  ) => (message: SystemMessage) => SystemMessage | undefined;
}

export const getters: GetterTree<State, State> & Getters = {
  lastMessage: state => {
    return state.dialog.messages.length > 0
      ? state.dialog.messages[state.dialog.messages.length - 1]
      : undefined;
  },

  systemMessages: state => {
    return state.dialog.messages.filter((m): m is SystemMessage =>
      isSystemMessage(m)
    );
  },

  lastSystemMessage: state => {
    return [...state.dialog.messages]
      .reverse()
      .find((m): m is SystemMessage => isSystemMessage(m));
  },

  lastRequestSystemMessage: state => {
    return [...state.dialog.messages]
      .reverse()
      .find(
        (m): m is SystemMessage =>
          isSystemMessage(m) &&
          m.metadata?.systemIntent?.type !== SystemIntentType.INFORMATIVE
      );
  },

  lastMessageIsRequestSystemMessage: (_, getters) => {
    // TODO: solve typescript issue
    const lm = getters.lastMessage as SystemMessage | UserMessage | undefined;
    const lrsm = getters.lastRequestSystemMessage as SystemMessage | undefined;
    return (
      lm !== undefined &&
      lrsm !== undefined &&
      isSystemMessage(lm) &&
      (lm as SystemMessage) === lrsm
    );
  },

  // True if the last message as any assists which are for locations
  lastSystemMessageExpectsLocation: (_, getters) => {
    // TODO: solve typescript issue
    const lastSystemMessage = getters.lastSystemMessage as
      | SystemMessage
      | undefined;
    return lastSystemMessage !== undefined &&
      lastSystemMessage.assists != null &&
      lastSystemMessage.assists.length > 0
      ? lastSystemMessage.assists.some(a => a != null && a.location != null)
      : false;
  },

  // True if the last message as any assists which are for date
  lastSystemMessageExpectsDate: (_, getters) => {
    // TODO: solve typescript issue
    const lastSystemMessage = getters.lastSystemMessage as
      | SystemMessage
      | undefined;
    return lastSystemMessage !== undefined &&
      lastSystemMessage.assists != null &&
      lastSystemMessage.assists.length > 0
      ? lastSystemMessage.assists.some(a => a != null && a.date != null)
      : false;
  },

  // True if the last message as any assists which are for date
  lastSystemMessageExpectsDateRange: (_, getters) => {
    // TODO: solve typescript issue
    const lastSystemMessage = getters.lastSystemMessage as
      | SystemMessage
      | undefined;
    return lastSystemMessage !== undefined &&
      lastSystemMessage.assists != null &&
      lastSystemMessage.assists.length > 0
      ? lastSystemMessage.assists.some(a => a != null && a.dateRange != null)
      : false;
  },

  systemMessagesSinceLastUserMessage: state => {
    const reversed = [...state.dialog.messages].reverse();
    const lastUserMessage = reversed.find(m => !isSystemMessage(m));

    // else slice out and reverse up to the user message
    return (
      reversed
        .slice(
          0,
          lastUserMessage ? reversed.indexOf(lastUserMessage) : undefined
        )
        .reverse()
        // Ensure system message ... force the type
        .filter((m): m is SystemMessage => isSystemMessage(m))
    );
  },

  systemMessagesGrouped: state => {
    const grouped: SystemMessage[][] = [[]];
    let i = 0;
    state.dialog.messages.forEach(m => {
      if (isSystemMessage(m)) {
        grouped[i].push(m);
      } else {
        if (grouped[i].length === 0) {
          logger.error(
            '.systemMessagesGrouped: something went wrong grouping system messages. Should not have empty group.'
          );
        }
        i++;
        grouped[i] = [];
      }
    });
    return grouped;
  },

  // Use exact equality to match message, means that we cannot copy message
  isLastSystemMessageInGroup: (_, getters) => message => {
    // TODO: solve typescript issue
    const grouped = (getters.systemMessagesGrouped as unknown) as SystemMessage[][];
    const group = grouped.find(g => g.includes(message));
    if (group !== undefined) {
      return group.indexOf(message) === group.length - 1;
    } else {
      logger.error(
        '.isLastSystemMessageInGroup: Could not find system message in a group: ' +
          JSON.stringify(message)
      );
      return false;
    }
  },

  hasRepeatedSubtext: (_, getters) => message => {
    // TODO: solve typescript issue
    const systemMessages = (getters.systemMessages as unknown) as SystemMessage[];
    return (
      systemMessages.find(
        sm => sm.id != message.id && sm.subtext == message.subtext
      ) !== undefined
    );
  },

  // As long as not the last message, check if the next one is a user message
  isFollowedByUserMessage: state => message => {
    const index = state.dialog.messages.indexOf(message);
    if (index !== undefined && index !== state.dialog.messages.length - 1) {
      return !isSystemMessage(state.dialog.messages[index + 1]);
    } else {
      return false;
    }
  },

  // True if we have a non-informative message since the last user message
  // Allows for the weird situation where an informative follows a prompt
  haveActiveRequestSystemMessage: (_, getters) => {
    // TODO: solve typescript issue with getters
    return ((getters.systemMessagesSinceLastUserMessage as unknown) as SystemMessage[]).some(
      m => m.metadata?.systemIntent?.type !== SystemIntentType.INFORMATIVE
    );
  },

  haveFatalError: state => {
    const haveFatal = state.dialog.messages
      .filter((m): m is SystemMessage => isSystemMessage(m))
      .find(m => m.metadata?.systemIntent?.type == SystemIntentType.ERROR);
    // TODO: solve typescript issue with getters
    return haveFatal != undefined;
  },

  requireMultiChoiceResponse: (state, getters) => {
    const lsm = (getters.lastSystemMessage as unknown) as
      | SystemMessage
      | undefined;
    const lrsm = (getters.lastRequestSystemMessage as unknown) as
      | SystemMessage
      | undefined;
    return lsm != undefined && lrsm != undefined
      ? lsm === lrsm &&
          isMultiChoice(lrsm) &&
          lrsm.disableTextEntry != null &&
          lrsm.disableTextEntry
      : false;
  },

  isTextFeedBackPrompt: (status, getters) => {
    const lsm = (getters.lastSystemMessage as unknown) as
        | SystemMessage
        | undefined;

    return lsm?.metadata?.systemIntent?.type == "pass through" && lsm?.metadata.systemIntent.subType == "user feedback";
  },

  multiChoiceChoices: (state, getters) => {
    const lsm = (getters.lastSystemMessage as unknown) as
      | SystemMessage
      | undefined;
    const lrsm = (getters.lastRequestSystemMessage as unknown) as
      | SystemMessage
      | undefined;
    if (lsm === undefined || lrsm === undefined || lsm !== lrsm) return [];
    return unMaybeArray(
      lrsm.multiChoice != null ? lrsm.multiChoice.choices : undefined
    );
  },

  requireDialogResponse: (state, getters) => {
    const rmc = (getters.requireMultiChoiceResponse as unknown) as boolean;
    // Forcing dialog response in the case of fatal error - which is nothing right now
    const hfe = (getters.haveFatalError as unknown) as boolean;
    const fbp = (getters.isTextFeedBackPrompt as unknown) as boolean;
    return rmc || hfe || fbp;
  },

  nextSystemMessage: state => message => {
    const index = state.dialog.messages.indexOf(message);
    return state.dialog.messages
      .slice(index + 1)
      .find((m): m is SystemMessage => isSystemMessage(m));
  },

  // We disable text entry if we:
  // - don't have at least one request
  // - if the last request disabled text entry
  // - if we have a fatal error
  disabledTextEntry: (_, getters) => {
    const lrsm = (getters.lastRequestSystemMessage as unknown) as
      | SystemMessage
      | undefined;
    const haveFatalError = (getters.haveFatalError as unknown) as boolean;
    return lrsm != undefined
      ? (lrsm.disableTextEntry != null && lrsm.disableTextEntry) ||
          haveFatalError
      : true;
  },

  disabledSend: (state, getters) => {
    const harsm = (getters.haveActiveRequestSystemMessage as unknown) as boolean;
    const haveFatalError = (getters.haveFatalError as unknown) as boolean;
    return (
      state.dialog.waitingOnDialogUpdateAfterSend || !harsm || haveFatalError
    );
  },
};
