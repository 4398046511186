import {
  AiEcApiCoggigsV1ExternalTaskAssignmentStatus,
  AiEcApiDialogV1PropertyInput,
  AiEcApiDialogV1SystemMessage as SystemMessage,
  AiEcApiDialogV1UserAction as UserAction,
  AiEcApiDialogV1UserMessage as UserMessage,
  AiEcApiDialogV1UserTextChoice as UserTextChoice,
  AiEcApiMetadataV1Metadata as Metadata,
  AiEcApiRtwV1Flight as Flight,
  AiEcApiRtwV1Leg as Leg,
  AiEcApiSessionV1PropertyInput,
  GoogleProtobufTimestamp as Timestamp,
  GoogleTypeDate as LocalDate,
  GoogleTypeDateTime as LocalDateTime,
  Maybe,
} from '@ec/rtw-graphql';
import {differenceInCalendarDays, format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {jsonToGraphQLQuery as j2q} from 'json-to-graphql-query';

export {
  AiEcApiRtwV1Status as Status,
  AiEcApiSessionV1StatusType as SessionStatusType,
  AiEcApiDialogV1StatusType as DialogStatusType,
  AiEcApiRtwV1SeatClass as SeatClass,
  AiEcApiRtwV1CitiesPhase as CitiesPhase,
  AiEcApiRtwV1DatesPhase as DatesPhase,
  AiEcApiRtwV1FlightsPhase as FlightsPhase,
  AiEcApiRtwV1BookingPhase as BookingPhase,
} from '@ec/rtw-graphql';


// Prettier warning on export requires update to v2+
// eslint-disable-next-line prettier/prettier
export type {
  Maybe,
  AiEcApiMetadataV1Metadata as Metadata,
  GoogleTypeDate as LocalDate,
  GoogleTypeDateTime as LocalDateTime,
  GoogleProtobufTimestamp as Timestamp,
  // Session
  AiEcApiSessionV1Session as Session,
  AiEcApiSessionV1Status as SessionStatus,
  // Saving
  AiEcApiRtwV1SaveItineraryRequestInput as SaveItineraryRequest,

  // Geography
  AiEcApiRtwV1Conference as Conference,
  AiEcApiRtwV1Continent as Continent,
  AiEcApiRtwV1Country as Country,
  AiEcApiRtwV1City as City,
  AiEcApiRtwV1Cities as Cities,
  AiEcApiRtwV1GeographyResponse as GeographyResponse,
  // Flights
  AiEcApiRtwV1Carrier as Carrier,
  AiEcApiRtwV1Flight as Flight,
  AiEcApiRtwV1Leg as Leg,
  AiEcApiRtwV1TravelInfo as TravelInfo,
  AiEcApiRtwV1PriceSummary as PriceSummary,
  AiEcApiRtwV1Price as Price,
  GoogleTypeMoney as Money,
  // Itinerary
  AiEcApiRtwV1Itinerary as Itinerary,
  AiEcApiRtwV1Segment as Segment,
  AiEcApiRtwV1FlightSegment as FlightSegment,
  AiEcApiRtwV1Interim as Interim,
  AiEcApiRtwV1Phase as Phase,

  // Dialog
  AiEcApiDialogV1DialogBot as DialogBot,
  AiEcApiDialogV1SystemResponse as SystemResponse,
  AiEcApiDialogV1Status as DialogStatus,
  AiEcApiDialogV1History as History,
  AiEcApiDialogV1Message as Message,
  AiEcApiDialogV1SystemMessage as SystemMessage,
  AiEcApiDialogV1UserMessage as UserMessage,
  AiEcApiDialogV1UserText as UserText,
  AiEcApiDialogV1UserAction as UserAction,
  AiEcApiDialogV1UserTextInput as UserTextInput,
  AiEcApiDialogV1UserIntentInput as UserIntent,
  AiEcApiDialogV1UserTextChoice as UserTextChoice,
  AiEcApiDialogV1UserMessageMetadata as UserMessageMetadata,
  AiEcApiDialogV1Choice as Choice,
  AiEcApiRtwV1Completion as Completion,
  AiEcApiRtwV1CompletionFocus as CompletionFocus,
  AiEcApiRtwV1CompletionFocusMetadata as CompletionFocusMetadata,
  // Cog-gigs
  AiEcApiRtwV1ExternalTaskAssignmentRequestInput as AssignmentRequest,
  AiEcApiRtwV1ExternalTaskAssignmentResponse as AssignmentResponse,
  AiEcApiCoggigsV1ExternalTaskAssignmentStatus as AssignmentStatus,
  // Kr
  AiEcApiCogsV1Concept as Concept,
  AiEcApiCogsV1Entity as Entity,
  AiEcApiCogsV1Literal as Literal,
  AiEcApiCogsV1Formula as Formula,
  AiEcApiCogsV1FormulaPropertyValue as PropertyValue,
  AiEcApiRtwV1Airport as Airport,
} from '@ec/rtw-graphql';

// TODO: move to backend
export enum SystemIntentType {
  PROMPT = 'prompt',
  INFORMATIVE = 'informative',
  ERROR = 'error'
}

export type Tooltip = {
  id: string;
  title: string;
  content: string;
  trigger: string;
  anchor: string;
  tag?: string;
}


// Queries

const timestamp = { seconds: true, nanos: true };
const localDate = { year: true, month: true, day: true };
const localDateTime = { year: true, month: true, day: true, hours: true, minutes: true, seconds: true };
const dateRange = { start: localDate, end: localDate };
const timeZone = { id: true, version: true };
const zonedDate = { date: localDate, timeZone };
const dataConstraint = { selected: localDate, constraints: { label: true } };
const zonedDateConstraint = { selected: zonedDate,  constraints: { label: true } };
const locationConstraint = { constraints: { type: true, locationType: true, locationIds: true} };
const dateRangeConstraint = { selected: dateRange, constraints: { label: true, range: dateRange }}

// Leaf KR
const basicTerm = { name: true }
const literal = {
  i: true,
  d: true,
  s: true,
  b: true,
  time: timestamp,
};
const variable = {
  name: true,
}

// KR - explicit layering of recursion

// 4th layer can only be a primitive
const concept4 = {
  entity: {
    basicTerm,
    literal,
  },
  variable,
  predicate: true,
  role: true
}

// 3rd layer
const propertyValue3 = { property: true, value: concept4 }
const concept3 = {
  entity: {
    basicTerm,
    literal,
    functionalTerm: { predicate: true, propertyValues: propertyValue3 },
  },
  formula: { predicate: true, propertyValues: propertyValue3 },
  variable,
  set: { concepts: concept4 },
  predicate: true,
  role: true
}


// 2nd layer
const propertyValue2 = { property: true, value: concept3 }
const concept2 = {
  entity: {
    basicTerm,
    literal,
    functionalTerm: { predicate: true, propertyValues: propertyValue2 },
  },
  formula: { predicate: true, propertyValues: propertyValue2 },
  variable,
  set: { concepts: concept3 },
  predicate: true,
  role: true
}

// 1st layer
const propertyValue = { property: true, value: concept2 }

const formula = { predicate: true, propertyValues: propertyValue }

const concept = {
  entity: {
    basicTerm,
    literal,
    functionalTerm: { predicate: true, propertyValues: propertyValue },
  },
  formula,
  variable,
  set: { concepts: concept2 },
  predicate: true,
  role: true
}


const user = { id: true, tenantId: true };

const sessionStatus = { type: true, message: true, start: timestamp, estimatedEnd: timestamp };
const actor = { user, system: user };

const money = { currencyCode: true, units: true, nanos: true };
const price = { seatClass: true, total: money, base: money, tax: money };

const session = {
  id: true,
  actor,
  created: timestamp,
  lastAccess: timestamp,
  destroyed: timestamp,
};

const pairOfInts = {
  first: true,
  second: true,
}

const dataFromUser = {
  text: true,
  type: true,
  span: pairOfInts,
}

const userIntent = {
  intent: true,
  originalText: true,
  dataFromUser: dataFromUser,
}

const userTextProcessed = {
  intent: userIntent,
  parsingSummary: true,
  processedText: true,
}

const userTextInput = {
  text: true,
  processed: userTextProcessed,
}

const userTextChoice = {
  displayText: true,
  submitText: true,
}

const userAction = {
  text: true,
  action: formula,
}

const choice = {
  index: true,
  action: userAction,
  textChoice: userTextChoice,
  onSubmit: formula,
  isMutuallyExclusive: true,
}

const systemIntent = {
  type: true,
  subType: true,
  field: true,
  authenticationAction: userAction,
}

const systemMessage = {
  id: true,
  text: true,
  subtext: true,
  metadata: {
    flags: true,
    systemIntent,
  },
  multiChoice: {
    choices: choice,
    minRequired: true,
    maxAllowed: true
  },
  date: dataConstraint,
  zonedDate: zonedDateConstraint,
  location: locationConstraint,
  timestamp: timestamp,
  disableTextEntry: true,
  assists: {
    date: dataConstraint,
    zonedDate: zonedDateConstraint,
    location: locationConstraint,
    dateRange: dateRangeConstraint,
  },
};

const userMessage = {
  id: true,
  timestamp: timestamp,
  metadata: { impliesMutation: true },
  textInput: userTextInput,
  textChoices: { textChoices: userTextChoice },
  actions: { actions: userAction },
};


const priceSummary = {
  preferredCurrency: true,
  price: price,
  perAdultEconomy: price,
  perAdultBusiness: price,
  perAdultFirst: price,
  status,
  statusMessage: true,
};

const city = { code: true, countryCode: true, name: true, lat: true, lon: true, timeZone: true, hasAmbiguousName: true };
const cityShort = { code: true, name: true, timeZone: true };
const country = { code: true, continentCode: true, conferenceCode: true, name: true };
const continent = { code: true, conferenceCode: true, name: true };
const airport = { code: true, name: true };
const carrier = { code: true, name: true };
const cities = { cities : city };

const interim = {
  city,
  visit: concept,
  arrivalTime: timestamp,
  arrivalLocalDate: localDate,
  arrivalLocalDateTime: localDateTime,
  departureTime: timestamp,
  departureLocalDate: localDate,
  departureLocalDateTime: localDateTime,
  status: true,
  statusMessage: true,
  preferences: formula,
};

const rbd = {
  code: true,
  isPremium: true,
  selected: true,
  tooltip: true,
  value: true,
}

const surcharge = {
  amount: true,
  currency: true,
  rbd,
}

const userClass = {
  amadeusClassCode: true,
  availableSeats: true,
  bookingNotes: true,
  ubCabinClass: true,
  ubIndicator: true,
  userSeatClass: true,
  surcharge,
}

const leg = {
  id: true,
  departureCity: city,
  departureAirport: airport,
  departureTerminal: true,
  departureTime: timestamp,
  arrivalCity: city,
  arrivalAirport: airport,
  arrivalTerminal: true,
  arrivalTime: timestamp,
  flightNumber: true,
  flightMinutes: true,
  layoverMinutes: true,
  carrier: carrier,
  userClass,
};

const flight = {
  id: true,

  departureCity: cityShort,
  departureAirport: airport,
  departureTerminal: true,
  departureTime: timestamp,

  arrivalCity: cityShort,
  arrivalAirport: airport,
  arrivalTerminal: true,
  arrivalTime: timestamp,

  elapsedTime: true,
  flightMiles: true,
  legs: leg,
  flightNumber: true,
};

const FlightSegment = {
  selectedFlight: flight,
  flightOptions: flight,
}

const GroundSegment = {
}

const segment = {
  sequence: true,

  departure: city,
  departureVisit: concept,
  departureTime: timestamp,
  departureLocalDate: localDate,
  departureLocalDateTime: localDateTime,

  arrival: city,
  arrivalVisit: concept,
  arrivalTime: timestamp,
  arrivalLocalDate: localDate,
  arrivalLocalDateTime: localDateTime,

  flightSegment: FlightSegment,
  groundSegment: GroundSegment,

  status: true,
  statusMessage: true,

  preferences: formula,
  possibleOneStopOverCities: city,
  possibleTwoStopOverCities: cities,
};

const phase = {
  cities: true,
  dates: true,
  flights: true,
  booking: true,
};

const startDateRange = {
  start: localDate,
  end: localDate,
};

const itinerary = {
  name: true,
  origin: city,
  originVisit: concept,
  final: city,
  finalVisit: concept,

  departureTimestamp: timestamp,
  departureLocalDate: localDate,
  departureLocalDateTime: localDateTime,
  departurePreferences: formula,

  arrivalTimestamp: timestamp,
  arrivalLocalDate: localDate,
  arrivalLocalDateTime: localDateTime,
  arrivalPreferences: formula,

  interims: interim,
  segments: segment,
  citiesDone: true,
  datesDone: true,
  flightsDone: true,
  status: true,
  statusMessage: true,
  email: true,
  phase: phase,
  tripDuration: true,
  startDateRange: startDateRange,
};

const travelInfo = {
  numAdults: true,
  numChildren: true,
  preferredCarrier: carrier,
  seatClass: true,
};

const completionFocus = {
  text: true,
  type: true,
  confidence: true,
  entityId: true,
  entityType: true,
  metadata: { key: true, value: true },
  span: { begin: true, end: true },
};

const completion = {
  text: true,
  subtext: true,
  metatext: true,
  originalText: true,
  confidence: true,
  foci: completionFocus,
};



//
// Dialog

const dialogBotStatus = {
  id: true,
  botId: true,
  type : true,
  message: true,
  start: timestamp,
  estimatedEnd: timestamp,
}

const dialogSystemResponse = {
  status: dialogBotStatus,
  history: {
    messages: {
      systemMessage: systemMessage,
      userMessage: userMessage,
    },
  },
}


export function metadata(sessionId: string, userId?: string, tenantId?: string, token?: string): Metadata {
  return {
    sessionId,
    actor: {
      user: {
        id: userId ? userId : null,
        tenantId: tenantId ? tenantId : null
      }
    },
    token: token ? token : null,
  };
}

//
// Mutations
//

export const geographyData = (metadata: Metadata) => j2q({
  mutation: {
    aiEcApiRtwV1RtwServiceGeography: {
      __args: { input: { metadata } } ,
      cities: city,
      carriers: carrier,
      countries: country,
      continents: continent,
    },
  },
});

export const sessionCreate = (metadata: Metadata) => j2q({
  mutation: {
    aiEcApiSessionV1SessionServiceCreate: {
      __args: { input: { metadata } },
      session: session,
    },
  },
});

export const sessionGet = (metadata: Metadata) => j2q({
  query: {
    aiEcApiSessionV1SessionServiceGet: {
      __args: { input: { metadata } } ,
      session,
    },
  },
});

export const sessionDestroy = (metadata: Metadata) => j2q({
  mutation: {
    aiEcApiSessionV1SessionServiceDestroy: {
      __args: { input: { metadata } },
      sessionId: true,
    },
  },
});

export const dialogBotCreate = (metadata: Metadata, type: string, properties : Array<AiEcApiDialogV1PropertyInput>) => j2q({
  mutation: {
    aiEcApiDialogV1DialogBotServiceCreate: {
      __args: { input: { metadata, type, properties } } ,
      dialogBot: { id: true },
    },
  },
});

export const dialogBotGet = (metadata: Metadata, botId: string) => j2q({
  query : {
    aiEcApiDialogV1DialogBotServiceGet: {
      __args: { input: {
        metadata,
        botId,
      }} ,
      dialogBot: { id: true },

    },
  },
});

export const dialogBotDestroy = (metadata: Metadata, botId: string) => j2q({
  mutation: {
    aiEcApiDialogV1DialogBotServiceDestroy: {
      __args: { input: {
        metadata,
        botId,
      }},
      botId: true,
    },
  },
});

export const dialogSend = (metadata: Metadata, userMessage: UserMessage) => j2q({
  mutation: {
    aiEcApiDialogV1DialogBotServiceSend: {
      __args: { input: {
        metadata,
        userMessage,
      }},
      userMessage: { id: true },
    },
  },
});

export const userMessageText = (botId: string, text: string) => {
  return {
    botId,
    textInput: { text: encodeURIComponent(text) },
  }
};

export const userMessageTextChoices = (botId: string, textChoices: UserTextChoice[]) => {
  return {
    botId,
    textChoices: { textChoices }
  }
};

export const userMessageActions = (botId: string, actions: UserAction[]) => {
  return {
    botId,
    actions: { actions }
  }
};

// TODO: need better error checking around this
export const externalTaskAssignment = (
  metadata: Metadata,
  taskId?: string,
  hitId?: string,
  assignmentId?: string,
  workerId?: string,
  status?: AiEcApiCoggigsV1ExternalTaskAssignmentStatus,
) => j2q({
  mutation: {
    aiEcApiRtwV1RtwServiceExternalTaskAssignment: {
      __args: { input: {
        metadata,
        assignment: {
          taskId,
          hitId,
          assignmentId,
          workerId,
          statusAsString: status?.toString(),
        },
      }},
      metadata: { sessionId: true },
    },
  },
});

export const autocomplete = (metadata: Metadata, botId: string, text: string) => j2q({
  mutation: {
    aiEcApiRtwV1RtwServiceAutocomplete: {
      __args: { input: {
        metadata,
        botId,
        encodedText: encodeURIComponent(text),
      }},
      completions: completion,
    },
  },
});

export const saveItinerary = (metadata: Metadata, email: string, name: string) => j2q({
  mutation: {
    aiEcApiRtwV1RtwServiceSaveItinerary: {
      __args: { input: {
        metadata,
        email: email,
        itineraryName: name,
      }},
      linkId: true,
    },
  },
});


//
// Subscriptions
//

export const sessionStatusStream = (metadata: Metadata) => j2q({
  subscription: {
    aiEcApiSessionV1SessionServiceStatus: {
      __args: { input: { metadata } },
      status: sessionStatus,
    },
  },
});

export const sessionStream = (metadata: Metadata, properties: Array<AiEcApiSessionV1PropertyInput> ) => j2q({
  subscription: {
    aiEcApiSessionV1SessionStreamServiceSession: {
      __args: { input: { metadata, properties: properties } },
      session: session,
      status: sessionStatus,
    },
  },
});

export const itineraryStream = (metadata: Metadata) => j2q({
  subscription: {
    aiEcApiRtwV1RtwServiceItinerary: {
      __args: { input: { metadata } } ,
      itinerary: itinerary,
    },
  },
});

export const dialogBotSubscribeStream = (metadata: Metadata, botId: string, type: string) => j2q({
  subscription: {
    aiEcApiDialogV1DialogBotServiceSubscribe: {
      __args: { input: {
        metadata,
        botId,
        type,
      }},
      systemResponse: dialogSystemResponse,
    },
  },
});


export const travelInfoStream = (metadata: Metadata) => j2q({
  subscription: {
    aiEcApiRtwV1RtwServiceTravelInfo: {
      __args: { input: { metadata } } ,
      travelInfo,
    },
  },
});

export const priceSummaryStream = (metadata: Metadata) => j2q({
  subscription: {
    aiEcApiRtwV1RtwServicePriceSummary: {
      __args: { input: { metadata } },
      priceSummary: priceSummary,
    },
  },
});

//
// Helpers
//

/** Un-Maybe an object */
export function unMaybe<T>(obj: Maybe<T> | undefined): T | undefined {
  return obj != null ? obj : undefined;
}

/** Un-Maybe an array of maybes. WARNING: Removes nulls/undefineds in array */
export function unMaybeArray<T>(obj: Array<Maybe<T>> | null | undefined ):  Array<T> {
  return obj != null ? obj.filter((o): o is T => o != null) : [];
}

export function enumValues(enumObj: {[key: string]: string}): string[]{
  const all = [];
  for(const key in enumObj){
    all.push(enumObj[key]);
  }
  return all;
}

//
// Messages
export function isSystemMessage(
  message: SystemMessage | UserMessage
): message is SystemMessage {
  return (message as SystemMessage).subtext !== undefined;
}

export function isMultiChoice(message: SystemMessage | null | undefined) {
  return message != null &&
    message.multiChoice != null &&
    message.multiChoice.choices != null &&
    message.multiChoice.choices.length > 0;
}

export function isSingleChoice(message: SystemMessage | null | undefined) {
  return message != null &&
      message.multiChoice != null &&
      message.multiChoice.choices != null &&
      message.multiChoice.choices.length > 0 &&
      message.multiChoice?.minRequired === 1 &&
      message.multiChoice?.maxAllowed === 1;
}


//
// Time

export const dayFormattingShortest = 'd MMM';
export const dayFormattingShort = 'd MMM yyyy';
export const dayFormatting = 'E d MMM yyyy';
export const dateFormatting = 'yyyy-MM-dd';
export const timeFormatting = 'h:mm aaa';

export enum FlightTimes {
  EarlyMorning = 'Early Morning',
  Morning = 'Morning',
  Afternoon = 'Afternoon',
  Evening = 'Evening',
}
/** return the equivalent Date which is in local time for context */
export function localDateToDate(localDate: LocalDate | undefined): Date | undefined {
  if (localDate && localDate.year && localDate.month && localDate.day) {
    return new Date(localDate.year, localDate.month - 1, localDate.day);
  } else {
    return undefined;
  }
}

/** return the equivalent Date which is in local time for context */
export function localDateTimeToDate(localDateTime: LocalDateTime | undefined): Date | undefined {
  if (localDateTime != null && localDateTime.year != null && localDateTime.month != null && localDateTime.day != null && localDateTime.hours != null && localDateTime.minutes != null && localDateTime.seconds != null) {
    return new Date(localDateTime.year, localDateTime.month - 1, localDateTime.day, localDateTime.hours, localDateTime.minutes, localDateTime.seconds);
  } else {
    return undefined;
  }
}

/** Date which is in local time for context, and convert to a localdate structure. */
export function dateToLocalDate(date: Date | undefined) {
  // Date is 0 index month
  return date ? { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() } : undefined;
}


export function timestampToDate(timestamp: Maybe<Timestamp> | undefined, timeZone: string | undefined): Date | undefined {
  if (timestamp != null && timeZone && timestamp.seconds != null && timestamp.nanos != null) {
    return utcToZonedTime(new Date(
      Number(timestamp.seconds) * 1000 + timestamp.nanos / 1000000000),
      timeZone);
  } else {
    return undefined;
  }
}

export function timestampToDateForClient(timestamp: Maybe<Timestamp> | undefined): Date | undefined {
  if (timestamp != null && timeZone && timestamp.seconds != null && timestamp.nanos != null) {
    return utcToZonedTime(new Date(
      Number(timestamp.seconds) * 1000 + timestamp.nanos / 1000000000),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
    );
  } else {
    return undefined;
  }
}

export function timestampToLocalDate(timestamp: Timestamp | undefined, timeZone: string | undefined): LocalDate | undefined {
  return dateToLocalDate(timestampToDate(timestamp, timeZone));
}

export function departureDateFlight(flight: Flight) {
  return timestampToDate(
      flight.departureTime,
      unMaybe(flight.departureCity?.timeZone)
  );
}

export function arrivalDateFlight(flight: Flight) {
  return timestampToDate(
      flight.arrivalTime,
      unMaybe(flight.arrivalCity?.timeZone)
  );
}

export function departureDateLeg(leg: Leg) {
  return timestampToDate(
      leg.departureTime,
      unMaybe(leg.departureCity?.timeZone)
  );
}

export function arrivalDateLeg(leg: Leg) {
  return timestampToDate(
      leg.arrivalTime,
      unMaybe(leg.arrivalCity?.timeZone)
  );
}

export function formatLocalDate(localDate: Maybe<LocalDate> | undefined, formatting: string): string | undefined {
  if (localDate != null) {
    const date = localDateToDate(localDate);
    if (date !== undefined) {
      return format(date, formatting);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
}

export function formatTimestamp(
  timestamp: Maybe<Timestamp> | undefined,
  timeZone: Maybe<string> | undefined,
  formatting: string,
): string | undefined {
  if (timestamp != null && timeZone != null) {
    const date = timestampToDate(timestamp, timeZone)
    if (date === undefined) {
      return undefined;
    }
    return format(date, formatting);
  } else {
    return undefined;
  }
}

export function minutesToDurationFormatter(timeInMinutes?: string): string {
  if (!timeInMinutes) return '-';

  // flight elapsed time comes back as minutes in a string
  const minutes = parseInt(timeInMinutes, 10);
  const hours = Math.floor(minutes / 60);
  const minutesRemainder = Math.floor(minutes % 60);
  return `${hours}h ${minutesRemainder}m`;
}


function diffStr(numDays: number) {
  const symbol = numDays < 0 ? '' : '+';
  const dayStr = Math.abs(numDays) > 1 ? 'days' : 'day';
  return `${symbol}${numDays} ${dayStr}`
}

// Formatted calendar days offset between start and end
// - undefined if 0 days difference
export function dateOffsetString(start?: Date, end?: Date) {
  if (start && end) {
    // Later day first in this call
    const diff = differenceInCalendarDays(end, start)
    if (diff !== 0) return diffStr(diff)
  }
  return undefined
}

//
// Kr
