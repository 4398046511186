import Logger from '@/logger';
const logger = new Logger('rtw:data');

// eslint-disable-next-line prettier/prettier
export type {
  // Don't know why, but we get warnings for both of these re-exported terms
  AiEcApiRtwV1ScheduleRequestInput as ScheduleRequest,
  AiEcApiRtwV1ScheduleResponse as ScheduleResponse,
} from '@ec/rtw-data-api';

import {
  AiEcApiRtwV1ScheduleRequestInput as ScheduleRequest,
  AiEcApiRtwV1ScheduleResponse as ScheduleResponse,
} from '@ec/rtw-data-api';

//
// Calls
//

export async function getSchedule(
  endpoint: string,
  request: ScheduleRequest
): Promise<ScheduleResponse> {
  return fetch(endpoint + '/schedule', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(request),
  })
    .then(response => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      } else {
        throw Error('GetSchedule failed');
      }
    })
    .catch(() => {
      logger.error(
        'Error loading schedule for request: ' + JSON.stringify(request)
      );
    });
}
