import Logger from '@/logger';
const logger = new Logger('rtw:store');

import { createClient } from 'graphql-ws';
import { ActionContext, ActionTree } from 'vuex';

import { v4 as uuid } from 'uuid';

import {
  AssignmentResponse,
  DialogBot,
  dialogBotCreate,
  dialogBotDestroy,
  dialogBotGet,
  dialogBotSubscribeStream,
  dialogSend,
  externalTaskAssignment,
  geographyData,
  GeographyResponse,
  itineraryStream,
  Maybe,
  metadata,
  priceSummaryStream,
  Session,
  sessionDestroy,
  SessionStatusType,
  sessionStream,
  autocomplete,
  Completion,
  SystemResponse,
  Tooltip,
  travelInfoStream,
  unMaybe,
  UserAction,
  UserMessage,
  userMessageActions,
  userMessageText,
  userMessageTextChoices,
  UserTextChoice,
} from '@/api/service';

import { Mutations, MutationTypes } from './mutations';

import {
  AiEcApiCoggigsV1ExternalTaskAssignmentStatus,
  AiEcApiDialogV1CreateResponse,
  AiEcApiDialogV1DestroyResponse,
  AiEcApiDialogV1GetResponse,
  AiEcApiRtwV1ItineraryResponse,
  AiEcApiRtwV1PriceSummaryResponse,
  AiEcApiRtwV1AutocompleteResponse,
  AiEcApiRtwV1TravelInfoResponse,
  AiEcApiSessionV1DestroyResponse,
  AiEcApiSessionV1PropertyInput,
  AiEcApiSessionV1SessionResponse,
} from '@ec/rtw-graphql';
import { getSchedule, ScheduleRequest, ScheduleResponse } from '@/api/data';

import { clone } from 'lodash-es';
import { CategoryItinerary, State } from '.';
import { initialize as LdClientInitialize } from 'launchdarkly-js-client-sdk';
import * as config from 'config';
import { nextTick } from 'vue';
import isMobile from 'is-mobile';

import tooltipConfig from '@/assets/onboarding/tooltips';
import LogRocket from 'logrocket';
import router from '@/router';

function getNavigatorLanguage(): string {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.language || 'en';
  }
}

export enum ActionTypes {
  Reset = 'Reset',
  Restart = 'Restart',
  SetupClient = 'SetupClient',
  SetupSession = 'SetupSession',
  SetupSessionStatusStream = 'SetupSessionStatusStream',
  HandleDisconnectSession = 'HandleDisconnectSession',
  RetryCreateSession = 'RetryReconnectSession',
  ResetSession = 'ResetSession',
  DestroySession = 'DestroySession',
  RunUnsubscriber = 'RunUnsubscriber',
  SetUnsubscriber = 'SetUnsubscriber',
  SetupPriceSummaryStream = 'SetupPriceSummaryStream',
  GetGeographyData = 'GetGeographyData',
  SendAssignmentRequest = 'SendAssignmentRequest',
  SetupItineraryStream = 'SetupItineraryStream',
  SetupDialogBot = 'SetupDialogBot',
  CreateDialogBot = 'CreateDialogBot',
  GetDialogBot = 'GetDialogBot',
  DestroyDialogBot = 'DestroyDialogBot',
  SetupDialogBotStream = 'SetupDialogBotStream',
  SendUserMessage = 'SendUserMessage',
  SendText = 'SendText',
  SendTextChoices = 'SendTextChoices',
  SendActions = 'SendActions',
  GetContinentGeoData = 'GetContinentGeoData',
  GetItineraryCategories = 'GetItineraryCategories',
  GetItineraries = 'GetItineraries',
  SetProposedMessage = 'SetProposedMessage',
  SetupTravelInfoStream = 'SetupTravelInfoStream',
  AssignTask = 'AssignTask',
  SubmitTurkTask = 'SubmitTurkTask',
  SetAmtParams = 'SetAmtParams',
  GetCompletions = 'GetCompletions',
  GetToolTips = 'GetToolTips',
  UpdateMatchingToolTips = 'UpdateMatchingToolTips',
  GetItineraryList = 'GetItineraryList',
  GetSchedule = 'GetSchedule',
  SendProcessingWarning = 'SendProcessingWarning',
}

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, 'commit'>;

// Note: exact statement of what is wanted from AugmentedActionContext is not required.
export interface Actions {
  [ActionTypes.Reset]({ state }: AugmentedActionContext): void;
  [ActionTypes.Restart]({ state }: AugmentedActionContext): void;
  [ActionTypes.SetupClient]({ state }: AugmentedActionContext): void;
  [ActionTypes.SetupSession]({ state }: AugmentedActionContext): void;
  [ActionTypes.SetupSessionStatusStream]({
    state,
  }: AugmentedActionContext): void;
  [ActionTypes.HandleDisconnectSession]({
    state,
  }: AugmentedActionContext): void;
  [ActionTypes.RetryCreateSession]({ state }: AugmentedActionContext): void;
  [ActionTypes.ResetSession]({ state }: AugmentedActionContext): void;
  [ActionTypes.DestroySession]({ state }: AugmentedActionContext): void;
  [ActionTypes.RunUnsubscriber](
    { state }: AugmentedActionContext,
    key: string
  ): void;
  [ActionTypes.SetUnsubscriber](
    { state }: AugmentedActionContext,
    { key, unsubscriber }: { key: string; unsubscriber?: () => void }
  ): void;
  [ActionTypes.SetupDialogBot]({ state }: AugmentedActionContext): void;
  [ActionTypes.CreateDialogBot]({ state }: AugmentedActionContext): void;
  [ActionTypes.GetDialogBot]({ state }: AugmentedActionContext): void;
  [ActionTypes.DestroyDialogBot]({ state }: AugmentedActionContext): void;
  [ActionTypes.SetupPriceSummaryStream]({
    state,
  }: AugmentedActionContext): void;
  [ActionTypes.GetGeographyData]({ state }: AugmentedActionContext): void;
  [ActionTypes.SetupItineraryStream]({ state }: AugmentedActionContext): void;
  [ActionTypes.SetupDialogBotStream]({ state }: AugmentedActionContext): void;
  [ActionTypes.SendUserMessage](
    { state }: AugmentedActionContext,
    userMessage: UserMessage
  ): void;
  [ActionTypes.SendText]({ state }: AugmentedActionContext, text: string): void;
  [ActionTypes.SendTextChoices](
    { state }: AugmentedActionContext,
    texts: UserTextChoice[]
  ): void;
  [ActionTypes.SendActions](
    { state }: AugmentedActionContext,
    actions: UserAction[]
  ): void;
  [ActionTypes.GetContinentGeoData]({
    state,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AugmentedActionContext): Promise<any>;

  [ActionTypes.SendProcessingWarning]({
    state,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AugmentedActionContext): Promise<any>;
  [ActionTypes.GetItineraryCategories]({
    state,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AugmentedActionContext): Promise<any>;
  [ActionTypes.GetItineraries](
    { state }: AugmentedActionContext,
    category: string
  ): Promise<CategoryItinerary[]>;
  [ActionTypes.SetProposedMessage](
    { commit }: AugmentedActionContext,
    message: string
  ): void;
  [ActionTypes.SetupTravelInfoStream]({ state }: AugmentedActionContext): void;
  [ActionTypes.AssignTask]({ state }: AugmentedActionContext): void;
  [ActionTypes.SubmitTurkTask]({ state }: AugmentedActionContext): void;
  [ActionTypes.SetAmtParams](
    { state }: AugmentedActionContext,
    {
      taskId,
      workerId,
      assignmentId,
      hitId,
      turkSubmitTo,
    }: {
      taskId: string | undefined;
      workerId: string | undefined;
      assignmentId: string | undefined;
      hitId: string | undefined;
      turkSubmitTo: string | undefined;
    }
  ): void;
  [ActionTypes.GetCompletions](
    { state }: AugmentedActionContext,
    value: string
  ): void;
  [ActionTypes.GetToolTips]({ commit }: AugmentedActionContext): void;
  [ActionTypes.UpdateMatchingToolTips]({
    state,
    commit,
  }: AugmentedActionContext): void;
  [ActionTypes.GetItineraryList]({ commit }: AugmentedActionContext): void;
  [ActionTypes.GetSchedule](
    { state }: AugmentedActionContext,
    request: ScheduleRequest
  ): Promise<ScheduleResponse>;
}

export const actions: ActionTree<State, State> & Actions = {
  // Reset, run on App load
  // Reset tries to load from a previous session.
  async [ActionTypes.Reset]({ dispatch }) {
    logger.debug('::Reset: ...');
    // Setup client
    await dispatch(ActionTypes.SetupClient);
    // Setup session
    await dispatch(ActionTypes.SetupSession);
  },

  async [ActionTypes.Restart]({ dispatch, commit }) {
    logger.debug('::Restart: ...');

    commit(MutationTypes.SetHandleDisconnect, false);
    // Reset the session
    await dispatch(ActionTypes.ResetSession);
    // cleanup old session
    await dispatch(ActionTypes.DestroySession);

    commit(MutationTypes.SetHandleDisconnect, true);
    // Setup new Session
    await dispatch(ActionTypes.SetupSession);
  },

  // Create a client for connection
  async [ActionTypes.SetupClient]({ state, getters, commit, dispatch }) {
    logger.debug('::SetupClient: ...');
    const client = createClient({
      url: getters.endpointWs,
      retryAttempts: 0,
      connectionParams: {},
      on: {
        connected: () => logger.debug('Connected to server'),
        closed: event => {
          commit(MutationTypes.SetIsConnected, false);
          logger.debug('Connection close', event);
        },
        error: error => logger.error('Connection error', error),
      },
    });
    // Setup LDClient
    const user = {
      key: state.user.userId,
      uniqueId: uuid(),
      tenantId: state.user.tenantId,
    };

    const ldClient = LdClientInitialize(config.LAUNCHDARKLY_CLIENT_ID, user);
    const checkIfNeedToSwitchToMaintenanceMode = () => {
      const isMaintenanceMode: boolean = state.ldClient?.variation(
        'is-maintenance-mode',
        false
      );
      if (isMaintenanceMode) {
        logger.warn('moving to maintenance mode');
        router.replace({ name: 'Maintenance' });
      }
    };

    ldClient.on('ready', function() {
      logger.debug('LD client is now ready');
      commit(MutationTypes.SetLDClient, ldClient);
      dispatch(ActionTypes.GetToolTips);

      checkIfNeedToSwitchToMaintenanceMode();
    });
    ldClient.on('change:is-maintenance-mode', async (currentState: boolean) => {
      if (currentState) {
        logger.warn('going to maintenance mode');

        commit(MutationTypes.SetHandleDisconnect, false);
        await dispatch(ActionTypes.ResetSession);
        await dispatch(ActionTypes.DestroySession);
        await state.client?.dispose();
        await router.replace({ name: 'Maintenance' });
      } else {
        logger.warn('leaving maintenance mode');

        await router.replace({ name: 'Home' });

        await dispatch(ActionTypes.SetupClient);
        commit(MutationTypes.SetHandleDisconnect, true);
        await dispatch(ActionTypes.SetupSession);
      }
    });
    commit(MutationTypes.SetClient, client);
  },

  // TODO: in future we could check if this is a refresh in the same tab
  //       and re-connect to the tab's session.
  async [ActionTypes.SetupSession]({ state, commit, dispatch }) {
    logger.debug('::SetupSession: ...');

    await dispatch(ActionTypes.SetupSessionStatusStream);

    if (!state.isConnected && !state.handleDisconnect) {
      return;
    }
    // Make sure we got a session
    if (!state.session) {
      commit(MutationTypes.SetIsConnected, false);
      await dispatch(ActionTypes.RetryCreateSession);
      return;
    } else {
      LogRocket.identify(state.session.id ?? 'INVALID SESSION ID');
      commit(MutationTypes.SetIsConnected, true);
      commit(MutationTypes.ClearRetry, undefined);
    }

    // Geography data
    dispatch(ActionTypes.GetGeographyData);

    // Dialog bot
    await dispatch(ActionTypes.SetupDialogBot);

    // Setup streams
    await dispatch(ActionTypes.SetupDialogBotStream);
    await dispatch(ActionTypes.SetupTravelInfoStream);
    await dispatch(ActionTypes.SetupPriceSummaryStream);
    await dispatch(ActionTypes.SetupItineraryStream);
  },

  async [ActionTypes.SetupSessionStatusStream]({ state, commit, dispatch }) {
    const promise = new Promise<Session | undefined>((resolve, reject) => {
      // Subscribe to price summary
      const params = new URLSearchParams(window.location.search.substring(1));
      const whiteLabelCode: string = params.get('whiteLabelCode') || 'OMC';

      // NOTE: these named properties are checked on the backend as being part of an enum, however that enum
      //       is currently not available in the front end (because it is not directly used in protos).
      const properties: Array<AiEcApiSessionV1PropertyInput> = [
        {
          name: 'SESSION_PROPERTY_NAME_WHITE_LABEL_CODE',
          value: whiteLabelCode,
        },
        {
          name: 'SESSION_PROPERTY_NAME_LOCALE',
          value: getNavigatorLanguage(),
        },
        {
          name: 'SESSION_PROPERTY_NAME_TIMEZONE',
          value: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        {
          name: 'SESSION_PROPERTY_NAME_USER_AGENT',
          value: navigator.userAgent,
        },
        {
          name: 'SESSION_PROPERTY_NAME_SCREEN_HEIGHT',
          value: screen.height.toString(),
        },
        {
          name: 'SESSION_PROPERTY_NAME_SCREEN_WIDTH',
          value: screen.width.toString(),
        },
        {
          name: 'SESSION_PROPERTY_NAME_IS_MOBILE',
          value: isMobile().toString(),
        },
        {
          name: 'SESSION_PROPERTY_NAME_IS_MOBILE_OR_TABLET',
          value: isMobile({ tablet: true }).toString(),
        },
      ];

      const sessionId = state.sessionId ? state.sessionId : uuid();
      state.client?.subscribe(
        {
          query: sessionStream(
            // Note: can't use getters as state sessionId might not be set
            metadata(
              sessionId,
              state.user.userId,
              state.user.tenantId,
              state.user.token
            ),
            properties
          ),
        },
        {
          next: ({
            data,
          }: {
            data: {
              aiEcApiSessionV1SessionStreamServiceSession: AiEcApiSessionV1SessionResponse;
            };
          }) => {
            if (data.aiEcApiSessionV1SessionStreamServiceSession.status) {
              const status =
                data.aiEcApiSessionV1SessionStreamServiceSession.status;
              const statusType = unMaybe(status.type);
              if (statusType === undefined) {
                logger.error(
                  `.SetupSessionStatusStream: status type undefined.`
                );
                return;
              }

              switch (statusType) {
                case SessionStatusType.StatusTypeOk: {
                  commit(MutationTypes.SetIsConnected, true);
                  const session =
                    data.aiEcApiSessionV1SessionStreamServiceSession?.session;
                  commit(MutationTypes.SetSessionId, session?.id || undefined);
                  commit(MutationTypes.SetSessionStatus, status);
                  commit(MutationTypes.SetSession, session);
                  commit(MutationTypes.SetErrorMessage, undefined);
                  logger.info(
                    `.SetupSessionStatusStream: Session setup for id ${session?.id}`
                  );

                  resolve(session || undefined);

                  break;
                }
                case SessionStatusType.StatusTypeDestroyed:
                  logger.info(`Session statusType is DESTROYED`);
                  commit(MutationTypes.SetIsConnected, false);
                  commit(MutationTypes.SetSessionId, undefined);
                  commit(MutationTypes.SetHandleDisconnect, false);
                  break;
                case SessionStatusType.StatusTypeError:
                  logger.error(
                    `Session statusType is ERROR: ${status.message}`
                  );
                  commit(MutationTypes.SetIsConnected, false);
                  commit(MutationTypes.SetHandleDisconnect, true);
                  commit(
                    MutationTypes.SetErrorMessage,
                    status.message || 'Session Error'
                  );
                  reject(status.message);
                  break;
                default:
                  logger.error(
                    `Error while Setting up SessionStatusStream ${state.sessionStatus?.message}`
                  );
              }
            }
          },
          error: async e => {
            logger.error(e);
            logger.error(
              `::SetupSessionStatusStream: subscription to session status failed ... will try reconnect`
            );
            await dispatch(ActionTypes.HandleDisconnectSession);
          },
          complete: async () => {
            logger.error(
              '::SetupSessionStatusStream: lost connection ... will try to reconnect.'
            );
            // Handle
            await dispatch(ActionTypes.HandleDisconnectSession);
          },
        }
      );
    });
    return await promise;
  },

  async [ActionTypes.HandleDisconnectSession]({ state, commit, dispatch }) {
    logger.debug(`::HandleDisconnectSession: handling ${state.sessionId} ...`);

    if (!state.handleDisconnect) {
      logger.debug(
        `::HandleDisconnectSession: not handling disconnect [${state.sessionId}]`
      );
      return;
    }

    // Check if already retrying
    if (state.nextRetry) {
      logger.debug(
        `::HandleDisconnectSession: already in retry loop [${state.sessionId}]`
      );
    } else {
      // Note disconnect
      commit(MutationTypes.SetIsConnected, false);
      // Reset session

      await dispatch(ActionTypes.RetryCreateSession);
    }
  },

  // Destroy session and associated cleanup
  async [ActionTypes.RetryCreateSession]({ state, commit, dispatch }) {
    logger.debug(`::RetryReconnectSession: session [${state.sessionId}]`);

    // If next retry is set, then don't start another
    if (state.nextRetry) {
      logger.debug(
        `::RetryReconnectSession: already in retry loop [${state.sessionId}]`
      );
      return;
    }

    logger.debug(
      `::RetryCreateSession: retry in ${(state.retryWaitMs / 1000).toFixed(
        0
      )}s [${state.sessionId}]...`
    );

    setTimeout(async () => {
      commit(MutationTypes.ClearNextRetry, undefined);
      await dispatch(ActionTypes.ResetSession);
      await dispatch(ActionTypes.SetupSession);
    }, state.retryWaitMs);

    // Update retry wait and next
    commit(MutationTypes.NextRetry, undefined);
  },

  // Destroy session and associated cleanup
  async [ActionTypes.ResetSession]({ state, commit, dispatch }) {
    logger.debug(`::ResetSession: ... [${state.sessionId}]`);
    // Terminate all streams
    const cleanup = Object.keys(state.unsubscribers).map(
      async k => await dispatch(ActionTypes.RunUnsubscriber, k)
    );
    try {
      await Promise.all(cleanup);
    } catch (e) {
      logger.error(e);
      logger.error(`::ResetSession: failed to clean up subscribers.`);
    }
    // Destroy bot
    try {
      await dispatch(ActionTypes.DestroyDialogBot);
    } catch (e) {
      logger.warn(e);
      logger.warn(
        `::ResetSession: failed to destroy bot. Can fail here due to system disconnect.`
      );
    }
    // Clear out other data
    commit(MutationTypes.ClearItinerary, undefined);
    // Remove saved session
    commit(MutationTypes.SetSession, undefined);
    logger.debug(`::ResetSession: done [${state.sessionId}]`);
  },

  // Destroy session and associated cleanup
  async [ActionTypes.DestroySession]({ state, getters, commit }) {
    if (!state.sessionId) {
      logger.error("::DestroySession: don't have a valid session id");
      return;
    }

    logger.debug(
      `::DestroySession: clearing out session information [${state.sessionId}]`
    );

    // // Destroy the session
    await new Promise<AiEcApiSessionV1DestroyResponse | null | undefined>(
      (resolve, reject) => {
        let result: AiEcApiSessionV1DestroyResponse | null | undefined;
        state.client?.subscribe(
          {
            query: sessionDestroy(getters.metadata),
          },
          {
            next: ({
              data,
            }: {
              data: {
                aiEcApiSessionV1SessionServiceDestroy: AiEcApiSessionV1DestroyResponse;
              };
            }) => (result = data.aiEcApiSessionV1SessionServiceDestroy),
            error: reject,
            complete: () => resolve(result),
          }
        );
      }
    )
      .then((response: AiEcApiSessionV1DestroyResponse | null | undefined) => {
        if (response != null) {
          logger.debug('::DestroySession for ' + response.sessionId);
        }
      })
      .catch(e => {
        logger.error(e);
        logger.error(
          `::ReconnectSession: error reconnecting to session. This is fatal. [${state.sessionId}]`
        );
      });

    commit(MutationTypes.SetSession, undefined);
    logger.debug(`::DestroySession: done [${state.sessionId}]`);
    commit(MutationTypes.SetSessionId, undefined);
  },

  // if open then close a subscription
  async [ActionTypes.RunUnsubscriber]({ state, commit }, key: string) {
    logger.debug(
      `::RunUnsubscriber: Running unsubscriber for ${key} [${state.sessionId}]`
    );
    if (state.unsubscribers[key] !== undefined) {
      state.unsubscribers[key]();
      commit(MutationTypes.DeleteUnsubscriber, key);
    } else {
      logger.error(
        `::RunUnsubscriber: Tried to run an unsubscriber that does not exist: ${key} [${state.sessionId}]`
      );
    }
  },

  async [ActionTypes.SetUnsubscriber](
    { state, commit, dispatch },
    { key, unsubscriber }: { key: string; unsubscriber?: () => void }
  ) {
    // Force a stream cleanup
    if (state.unsubscribers[key] !== undefined) {
      dispatch(ActionTypes.RunUnsubscriber, key);
    }

    if (unsubscriber) {
      commit(MutationTypes.SetUnsubscriber, { key, unsubscriber });
    } else {
      logger.error(
        `::SetUnsubscriber: Trying to set an invalid stream cleanup. ${key} [${state.sessionId}]`
      );
    }
  },

  async [ActionTypes.SetupPriceSummaryStream]({
    state,
    getters,
    commit,
    dispatch,
  }) {
    // Scoped session id
    const sessionId = clone(state.sessionId);
    // Subscribe to price summary
    const unsubscriber = state.client?.subscribe(
      { query: priceSummaryStream(getters.metadata) },
      {
        next: ({
          data,
        }: {
          data: {
            aiEcApiRtwV1RtwServicePriceSummary: AiEcApiRtwV1PriceSummaryResponse;
          };
        }) => {
          if (data.aiEcApiRtwV1RtwServicePriceSummary.priceSummary) {
            commit(
              MutationTypes.SetPriceSummary,
              data.aiEcApiRtwV1RtwServicePriceSummary.priceSummary
            );
          }
        },
        error: e => {
          logger.error(e);
          logger.error(
            `::SetupPriceSummaryStream: Subsription to price summary failed`
          );
        },
        complete: () =>
          logger.debug(
            `::SetupPriceSummaryStream: subscription to price summary closed [${sessionId}]`
          ),
      }
    );
    // Set unsubscriber
    dispatch(ActionTypes.SetUnsubscriber, {
      key: ActionTypes.SetupPriceSummaryStream,
      unsubscriber,
    });
  },

  // Geography data
  async [ActionTypes.GetGeographyData]({ state, getters, commit }) {
    if (state.client === undefined) {
      logger.error('trying to setup geography stream, but no client');
      return;
    }

    // Start stream
    state.client?.subscribe(
      { query: geographyData(getters.metadata) },
      {
        next: ({
          data,
        }: {
          data: {
            aiEcApiRtwV1RtwServiceGeography: GeographyResponse;
          };
        }) => {
          if (data.aiEcApiRtwV1RtwServiceGeography != null) {
            commit(
              MutationTypes.SetGeographyData,
              data.aiEcApiRtwV1RtwServiceGeography
            );
          } else {
            commit(MutationTypes.SetGeographyData, {});
          }
        },
        error: () => logger.error('Could not get geography data.'),
        complete: () => {
          return;
        },
      }
    );
  },

  // itinerary stream
  async [ActionTypes.SetupItineraryStream]({
    state,
    getters,
    commit,
    dispatch,
  }) {
    if (state.client === undefined) {
      logger.error(
        '::SetupItineraryStream: trying to update itinerary, but no client'
      );
      return;
    }
    if (state.dialog.bot === undefined) {
      logger.error(
        '::SetupItineraryStream: trying to update itinerary, but no bot'
      );
      return;
    }
    // Scoped session id
    const sessionId = clone(state.sessionId);

    // Grab itinerary
    const unsubscriber = state.client.subscribe(
      { query: itineraryStream(getters.metadata) },
      {
        next: ({
          data,
        }: {
          data: {
            aiEcApiRtwV1RtwServiceItinerary: AiEcApiRtwV1ItineraryResponse;
          };
        }) => {
          if (data.aiEcApiRtwV1RtwServiceItinerary.itinerary != null) {
            commit(
              MutationTypes.SetItinerary,
              data.aiEcApiRtwV1RtwServiceItinerary.itinerary
            );

            if (
              data.aiEcApiRtwV1RtwServiceItinerary.itinerary?.segments &&
              data.aiEcApiRtwV1RtwServiceItinerary.itinerary?.segments[0]
                ?.arrivalLocalDate &&
              !state.hadDates
            ) {
              commit(MutationTypes.SetHadDates, true);
            }
          }
        },
        error: e => {
          logger.error(e);
          logger.error(`::SetupItineraryStream: error. [${sessionId}]`);
        },
        complete: () =>
          logger.debug(`::SetupItineraryStream: closed stream. [${sessionId}]`),
      }
    );

    // Set unsubscriber
    dispatch(ActionTypes.SetUnsubscriber, {
      key: ActionTypes.SetupItineraryStream,
      unsubscriber,
    });
  },

  async [ActionTypes.SetupDialogBot]({ state, dispatch }) {
    // Check if we got something
    if (state.dialog.bot && state.dialog.bot.id) {
      // Confirm that dialog bot exists. Pull from remote.

      await dispatch(ActionTypes.GetDialogBot);

      // If bot exists, continue
      logger.debug(
        `::setupDialogBot: continuing to use dialog bot ${state.dialog.bot?.id} [${state.sessionId}]`
      );
      if (!state.dialog.bot?.id) {
        logger.debug('need to create a new dialog bot');
        await dispatch(ActionTypes.CreateDialogBot);
      }
      // Else create bot
    } else {
      await dispatch(ActionTypes.CreateDialogBot);
    }
  },

  async [ActionTypes.CreateDialogBot]({ state, getters, commit }) {
    // Create dialog bot
    const params = new URLSearchParams(window.location.search.substring(1));
    const reType = params.get('reType') || 'REMOTE';

    const properties = state.dialog.properties;
    if (state.user.firstName) {
      properties.push({ name: 'FIRST_NAME', value: state.user.firstName });
    }
    await new Promise<DialogBot | null | undefined>((resolve, reject) => {
      let result: DialogBot | null | undefined;
      state.client?.subscribe(
        { query: dialogBotCreate(getters.metadata, reType, properties) },
        {
          next: ({
            data,
          }: {
            data: {
              aiEcApiDialogV1DialogBotServiceCreate: AiEcApiDialogV1CreateResponse;
            };
          }) => (result = data.aiEcApiDialogV1DialogBotServiceCreate.dialogBot),
          error: reject,
          complete: () => resolve(result),
        }
      );
    })
      .then((dialogBot: DialogBot | null | undefined) =>
        commit(MutationTypes.SetDialogBot, dialogBot)
      )
      .catch(e => {
        logger.error(e);
        logger.error(
          `::CreateDialogBot: error creating bot. [${state.sessionId}]`
        );
      });
  },

  async [ActionTypes.GetDialogBot]({ state, getters, commit }) {
    // We have an object to try to get dialog bot
    await new Promise<DialogBot | null | undefined>((resolve, reject) => {
      // If we have nothing to get
      if (!state.dialog.bot || !state.dialog.bot.id) {
        reject('::GetDialogBot: trying to get, but bot or bot id not set.');
        return;
      }

      let result: DialogBot | null | undefined;
      state.client?.subscribe(
        { query: dialogBotGet(getters.metadata, state.dialog.bot.id) },
        {
          next: ({
            data,
          }: {
            data: {
              aiEcApiDialogV1DialogBotServiceGet: AiEcApiDialogV1GetResponse;
            };
          }) => {
            if (data.aiEcApiDialogV1DialogBotServiceGet != null) {
              result = data.aiEcApiDialogV1DialogBotServiceGet.dialogBot;
            }
          },
          error: reject,
          complete: () => resolve(result),
        }
      );
    })
      .then((dialogBot: DialogBot | null | undefined) =>
        commit(MutationTypes.SetDialogBot, dialogBot)
      )
      .catch(e => {
        logger.error(e);
        logger.error(
          `::GetDialogBot: could not get dialog bot. Might not exist in backend. [${state.sessionId}]`
        );
        commit(MutationTypes.SetDialogBot, undefined);
      });
  },

  async [ActionTypes.DestroyDialogBot]({ state, getters, commit }) {
    await new Promise<void>((resolve, reject) => {
      if (!state.dialog?.bot?.id) {
        reject(
          '::DestroyDialogBot: Trying to destroy a bot when no bot is setup.'
        );
        return;
      }
      // Get bot id that is being destroyed
      const botId = state.dialog.bot.id;
      // Set dialog bot as undefined
      commit(MutationTypes.SetDialogBot, undefined);
      // Clear out dialog
      commit(MutationTypes.SetDialog, []);

      if (!state.isConnected) {
        reject('::DestroyDialogBot: Not connected');
        return;
      }
      // Send destroy
      state.client?.subscribe(
        { query: dialogBotDestroy(getters.metadata, botId) },
        {
          next: ({
            data,
          }: {
            data: {
              aiEcApiDialogV1DialogBotServiceDestroy: AiEcApiDialogV1DestroyResponse;
            };
          }) =>
            logger.debug(
              `::DestroyDialogBot: Destroyed bot ${data.aiEcApiDialogV1DialogBotServiceDestroy.botId} [${state.sessionId}]`
            ),
          error: e => {
            logger.error(e);
            logger.error(
              `::DestroyDialogBot: Failed to destroy bot ${botId} [${state.sessionId}]`
            );
          },
          complete: () => resolve(),
        }
      );
    });
  },

  async [ActionTypes.SetupDialogBotStream]({
    state,
    getters,
    commit,
    dispatch,
  }) {
    // Bot id
    const params = new URLSearchParams(window.location.search.substring(1));
    const reType = params.get('reType') || 'REMOTE';
    const botId = state.dialog.bot?.id;
    if (botId === null || botId === undefined) {
      logger.error('::SetupDialogBotStream: bot not setup.');
      return;
    }
    // Subscribe to system messages
    const unsubscriber = state.client?.subscribe(
      {
        query: dialogBotSubscribeStream(getters.metadata, botId, reType),
      },
      {
        next: ({
          data,
        }: {
          data: {
            aiEcApiDialogV1DialogBotServiceSubscribe: {
              systemResponse: SystemResponse;
            };
          };
        }) => {
          if (
            data.aiEcApiDialogV1DialogBotServiceSubscribe.systemResponse
              ?.history?.messages != null
          ) {
            // Update dialog
            commit(
              MutationTypes.SetDialog,
              data.aiEcApiDialogV1DialogBotServiceSubscribe.systemResponse
                ?.history?.messages
            );

            // No longer waiting in next tick
            nextTick(() => {
              commit(MutationTypes.SetWaitingOnDialogUpdateAfterSend, false);
            });
          } else if (
            data.aiEcApiDialogV1DialogBotServiceSubscribe.systemResponse
              ?.status != null
          ) {
            commit(
              MutationTypes.SetDialogStatus,
              data.aiEcApiDialogV1DialogBotServiceSubscribe.systemResponse
                ?.status
            );
          }
        },
        error: e => {
          logger.error(e);
          logger.error(
            `::SetupDialogBotStream: stream error. [${state.sessionId}]`
          );
        },
        complete: () =>
          logger.debug(
            `::SetupDialogBotStream: subscription to dialog for bot ${botId} closed [${state.sessionId}]`
          ),
      }
    );

    // Set stream cleanup
    dispatch(ActionTypes.SetUnsubscriber, {
      key: ActionTypes.SetupDialogBotStream,
      unsubscriber,
    });
  },

  // Send warning
  async [ActionTypes.SendProcessingWarning]({ commit }) {
    commit(
      MutationTypes.AddWarning,
      'Sorry, cannot currently accept input ... please wait to try again soon'
    );
    return;
  },

  // Send a user message
  async [ActionTypes.SendUserMessage](
    { state, getters, commit },
    userMessage: UserMessage
  ) {
    // Ensure we reset input box on a send
    commit(MutationTypes.SetDialogInputBoxText, '');

    // If sending is disabled
    if (getters.disabledSend) {
      logger.warn(
        '.SendUserMessage: Cannot send message because waiting on system. Did not send ' +
          JSON.stringify(userMessage)
      );
      commit(
        MutationTypes.AddWarning,
        'Sorry, cannot currently accept input ... please wait to try again soon'
      );
      return;
    }

    // Push user message onto dialog
    commit(MutationTypes.PushUserMessage, userMessage);

    // Set that we are waiting for dialog update
    commit(MutationTypes.SetWaitingOnDialogUpdateAfterSend, true);

    // send
    state.client?.subscribe(
      { query: dialogSend(getters.metadata, userMessage) },
      {
        next: () => {
          //do nothing
        },
        error: e => {
          logger.error(e);
          logger.error('::SendUserMessage: Failed to send user message');
        },
        complete: () => {
          return;
        },
      }
    );
  },

  // Send a user text message
  async [ActionTypes.SendText]({ state, dispatch }, text: string) {
    const botId = state.dialog.bot?.id;
    if (botId === null || botId === undefined) {
      logger.error('::SendUserMessage: bot not setup.');
      return;
    }
    dispatch(ActionTypes.SendUserMessage, userMessageText(botId, text));
  },

  // Send a user text option selection
  async [ActionTypes.SendTextChoices](
    { state, dispatch },
    textChoices: UserTextChoice[]
  ) {
    const botId = state.dialog.bot?.id;
    if (botId === null || botId === undefined) {
      logger.error('::SendUserMessage: bot not setup.');
      return;
    }
    dispatch(
      ActionTypes.SendUserMessage,
      userMessageTextChoices(botId, textChoices)
    );
  },

  // Send a user action options
  async [ActionTypes.SendActions]({ state, dispatch }, actions: UserAction[]) {
    const botId = state.dialog.bot?.id;
    if (botId === null || botId === undefined) {
      logger.error('::SendUserMessage: bot not setup.');
      return;
    }
    dispatch(ActionTypes.SendUserMessage, userMessageActions(botId, actions));
  },

  // TODO: type the geo data
  async [ActionTypes.GetContinentGeoData](): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any> {
    return await fetch('./data/geo/continents.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(resp => {
        return resp.json();
      })
      .then(data => {
        return data.records;
      })
      .catch(err => {
        logger.error('::GetContinentGeoData: Error loading continents: ', err);
      });
  },

  async [ActionTypes.GetItineraryCategories](): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any> {
    // Note: this will fail on localhost if you are not pointing to zen3 dev env
    return await fetch(config.ZEN3_API_URL + '/ThemeCategories', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then(resp => {
        return resp.json();
      })
      .catch(err => {
        logger.error('.GetItineraryCategories: loading continents: ', err);
      });
  },

  async [ActionTypes.GetItineraries](
    _,
    category
  ): Promise<CategoryItinerary[]> {
    // Note: this will fail on localhost if you are not pointing to zen3 dev env
    return await fetch(config.ZEN3_API_URL + '/ThemeCategoryItineraries', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        categoryName: category,
      }),
    })
      .then(resp => {
        return resp.json();
      })
      .catch(err => {
        logger.debug('.GetItineraries: loading itineraries: ', err);
      });
  },

  [ActionTypes.SetProposedMessage]({ commit }, message: string): void {
    commit(MutationTypes.SetDialogInputBoxText, message);
  },

  // TravelInfo Stream
  async [ActionTypes.SetupTravelInfoStream]({
    state,
    getters,
    commit,
    dispatch,
  }) {
    if (state.client === undefined) {
      logger.error(
        '::SetupTravelInfoStream: trying to update travelInfo, but no client'
      );
      return;
    }
    if (state.dialog.bot === undefined) {
      logger.error(
        '::SetupTravelInfoStream: trying to update travelInfo, but no bot'
      );
      return;
    }
    // Scoped session id
    const sessionId = clone(state.sessionId);

    // Start stream
    const unsubscriber = state.client?.subscribe(
      { query: travelInfoStream(getters.metadata) },
      {
        next: ({
          data,
        }: {
          data: {
            aiEcApiRtwV1RtwServiceTravelInfo: AiEcApiRtwV1TravelInfoResponse;
          };
        }) => {
          if (data.aiEcApiRtwV1RtwServiceTravelInfo.travelInfo != null) {
            commit(
              MutationTypes.SetTravelInfo,
              data.aiEcApiRtwV1RtwServiceTravelInfo.travelInfo
            );
          } else {
            commit(MutationTypes.SetTravelInfo, {});
          }
        },
        error: e => {
          logger.error(e);
          logger.error(
            `::SetupTravelInfoStream: Failed subscription to travel info [${sessionId}]`
          );
        },
        complete: () => {
          logger.debug(
            `::SetupDialogBotStream: subscription to travel info closed [${sessionId}]`
          );
        },
      }
    );
    // Set unsubscriber
    dispatch(ActionTypes.SetUnsubscriber, {
      key: ActionTypes.SetupTravelInfoStream,
      unsubscriber,
    });
  },

  [ActionTypes.AssignTask]({ state, getters }): void {
    // Start stream
    state.client?.subscribe(
      {
        query: externalTaskAssignment(
          getters.metadata,
          state.taskId,
          state.hitId,
          state.assignmentId,
          state.workerId,
          AiEcApiCoggigsV1ExternalTaskAssignmentStatus.ExternalTaskAssignmentStatusAssigned
        ),
      },
      {
        next: ({
          data,
        }: {
          data: {
            aiEcApiRtwV1RtwServiceExternalTaskAssignment: AssignmentResponse;
          };
        }) => {
          if (data.aiEcApiRtwV1RtwServiceExternalTaskAssignment != null) {
            // const metadata = data.aiEcApiRtwV1Assignment;
            // TODO: assign success
          } else {
            // TODO: assign unformatted response
          }
        },
        error: e => {
          logger.error(e);
          logger.error('::AssignTask: Failed to assign task');
        },
        complete: () => {
          return;
        },
      }
    );
  },

  // Submit Turk Task
  async [ActionTypes.SubmitTurkTask]({ state, getters }) {
    state.client?.subscribe(
      {
        query: externalTaskAssignment(
          getters.metadata,
          state.taskId,
          state.hitId,
          state.assignmentId,
          state.workerId,
          AiEcApiCoggigsV1ExternalTaskAssignmentStatus.ExternalTaskAssignmentStatusCompleted
        ),
      },
      {
        next: ({
          data,
        }: {
          data: {
            aiEcApiRtwV1Assignment: AssignmentResponse;
          };
        }) => {
          if (data.aiEcApiRtwV1Assignment != null) {
            // const metadata = data.aiEcApiRtwV1Assignment;
            // TODO: assign success
          } else {
            // TODO: assign unformatted response
          }
        },
        error: e => {
          logger.error(e);
          logger.error(
            '::SubmitTurkTask: Failed to submit turker task assignment'
          );
        },
        complete: () => {
          return;
        },
      }
    );
  },

  async [ActionTypes.SetAmtParams](
    { commit },
    {
      taskId,
      workerId,
      assignmentId,
      hitId,
      turkSubmitTo,
    }: {
      taskId: string | undefined;
      workerId: string | undefined;
      assignmentId: string | undefined;
      hitId: string | undefined;
      turkSubmitTo: string | undefined;
    }
  ) {
    // save to state
    if (taskId) {
      commit(MutationTypes.SetTaskId, taskId);
    }
    if (workerId) {
      commit(MutationTypes.SetWorkerId, workerId);
    }
    if (assignmentId) {
      commit(MutationTypes.SetAssignmentId, assignmentId);
    }
    if (hitId) {
      commit(MutationTypes.SetHitId, hitId);
    }
    if (turkSubmitTo) {
      commit(MutationTypes.SetTurkSubmitTo, turkSubmitTo);
    }
  },

  // single TravelInfo request
  async [ActionTypes.GetCompletions]({ state, getters, commit }, text) {
    // Need a bot
    const botId = state.dialog.bot?.id;
    if (botId === null || botId === undefined) {
      logger.error('::GetCompletions: bot not setup.');
      return;
    }
    // Force an immediate reset if value is length 0
    if (text.length === 0) {
      commit(MutationTypes.SetCompletions, []);
    }

    const completions = await new Promise<Maybe<Completion>[]>(
      (resolve, reject) => {
        let completions: Maybe<Completion>[] = [];
        state.client?.subscribe(
          { query: autocomplete(getters.metadata, botId, text) },
          {
            next: ({
              data,
            }: {
              data: {
                aiEcApiRtwV1RtwServiceAutocomplete: AiEcApiRtwV1AutocompleteResponse;
              };
            }) => {
              if (data.aiEcApiRtwV1RtwServiceAutocomplete.completions != null) {
                completions =
                  data.aiEcApiRtwV1RtwServiceAutocomplete.completions;
              }
            },
            error: reject,
            complete: () => resolve(completions),
          }
        );
      }
    );

    commit(MutationTypes.SetCompletions, completions);
  },

  async [ActionTypes.GetToolTips]({ state, commit }) {
    if (tooltipConfig?.length > 0) {
      const filteredTips = tooltipConfig.filter(e => {
        return e.tag == undefined
          ? true
          : state.ldClient?.variation(e?.tag, false);
      });
      commit(MutationTypes.SetTooltips, filteredTips);
    }
  },

  [ActionTypes.UpdateMatchingToolTips]({ state, commit }) {
    const matchingTips: Tooltip[] = [];
    if (state.allTooltips) {
      state.allTooltips.forEach((t: Tooltip) => {
        const match = document.evaluate(
          t.trigger,
          document.body,
          null,
          XPathResult.ANY_TYPE,
          null
        );
        const node = match.iterateNext() as HTMLElement;
        if (node) {
          const style = window.getComputedStyle(node);
          if (style.display !== 'none') {
            matchingTips.push(t);
          }
        }
      });
      if (matchingTips.length > 0) {
        commit(MutationTypes.SetMatchingTooltips, matchingTips);
      }
    }
  },

  async [ActionTypes.GetItineraryList]({ state, commit }) {
    if (
      state.user.token === undefined ||
      state.user.zen3 === undefined ||
      state.user.zen3.userId === undefined ||
      state.user.zen3.userKeyId === undefined
    ) {
      logger.debug(
        '.GetItineraryList: zen3 user data not defined - likely not logged in.'
      );
      return;
    }
    const response = await fetch(config.ZEN3_API_URL + '/GetItineraryList', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        token: state.user.token,
      },
      body: JSON.stringify({
        getItineraryList_Input: {
          customerCode: 'ONWIBE2',
          lang: 'EN',
          userName: state.user.zen3.userId,
          userKeyId: state.user.zen3.userKeyId,
        },
      }),
    })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      })
      .catch(err => {
        logger.error('Error loading itinerary list: ', err);
      });

    if (response === undefined) {
      logger.error(
        '.GetItineraryList: failed to get data. Because user is not logged in or token has expired'
      );
      commit(MutationTypes.SetItineraryList, []);
      return;
    }

    let count = 0;
    if (response.GetItineraryList_Output) {
      count = JSON.parse(response.GetItineraryList_Output.count);
    }

    if (count === 1) {
      commit(MutationTypes.SetItineraryList, [
        response.GetItineraryList_Output.itineraryListItem,
      ]);
    } else if (count > 1) {
      commit(
        MutationTypes.SetItineraryList,
        response.GetItineraryList_Output.itineraryListItem
      );
    } else {
      commit(MutationTypes.SetItineraryList, []);
    }
  },

  async [ActionTypes.GetSchedule](
    { state },
    request: ScheduleRequest
  ): Promise<ScheduleResponse> {
    return getSchedule(state.dataEndpoint, request);
  },
};

export default actions;
