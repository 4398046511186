import { State } from '@/store';
import { Getters as GlobalGetters } from '@/store/getters';

import {
  Interim,
  localDateTimeToDate,
  localDateToDate,
  Segment,
  timeFormatting,
  timestampToDate,
  unMaybeArray,
} from '@/api/service';
import { GetterTree } from 'vuex';
import {
  getUserRequestsArrivalDate,
  getUserRequestsDepartureDate,
  getUserRequestsMinDaysInVisit,
} from '@/api/kr';
import { differenceInCalendarDays, formatDistance } from 'date-fns';

export type Getters = {
  durationForInterim: (
    state: State,
    getters: GlobalGetters
  ) => (interim: Interim) => number | undefined;
  arrivalDateForInterim: (
    state: State,
    getters: GlobalGetters
  ) => (interim: Interim | undefined) => Date | undefined;
  departureDateForInterim: (
    state: State,
    getters: GlobalGetters
  ) => (interim: Interim | undefined) => Date | undefined;
  durationForSegment: (
    state: State,
    getters: GlobalGetters
  ) => (segment: Segment) => string | undefined;
  arrivalDateForSegment: () => (segment: Segment) => Date | undefined;
  departureDateForSegment: () => (segment: Segment) => Date | undefined;
  requestForInterimTimingOutOfSync: (
    state: State,
    getters: GlobalGetters
  ) => (interim: Interim) => boolean;
  requestForTimingOutOfSync: (state: State, getters: GlobalGetters) => boolean;
};

export const getters: GetterTree<State, State> & Getters = {
  //
  // Interims
  //

  durationForInterim: (_, getters) => interim => {
    const arrivalFn = (getters.arrivalDateForInterim as unknown) as (
      interim: Interim
    ) => Date | undefined;

    const departureFn = (getters.departureDateForInterim as unknown) as (
      interim: Interim
    ) => Date | undefined;

    const a = arrivalFn(interim);
    const d = departureFn(interim);
    if (a && d) {
      return differenceInCalendarDays(d, a);
    } else {
      return undefined;
    }
  },

  arrivalDateForInterim: () => interim => {
    if (interim?.arrivalLocalDate != null) {
      return localDateToDate(interim?.arrivalLocalDate);
    } else if (interim?.arrivalLocalDateTime != null) {
      return localDateTimeToDate(interim?.arrivalLocalDateTime);
    } else {
      return undefined;
    }
  },

  departureDateForInterim: () => interim => {
    if (interim?.departureLocalDate != null) {
      return localDateToDate(interim.departureLocalDate);
    } else if (interim?.departureLocalDateTime != null) {
      return localDateTimeToDate(interim.departureLocalDateTime);
    } else {
      return undefined;
    }
  },

  //
  // Segment
  //

  durationForSegment: (_, getters) => segment => {
    const arrivalFn = (getters.arrivalDateForSegment as unknown) as (
      segment: Segment
    ) => Date | undefined;

    const departureFn = (getters.departureDateForSegment as unknown) as (
      segment: Segment
    ) => Date | undefined;

    const a = arrivalFn(segment);
    const d = departureFn(segment);
    if (a && d) {
      return formatDistance(a, d);
    } else {
      return undefined;
    }
  },

  arrivalDateForSegment: () => (segment: Segment) => {
    if (segment.arrivalLocalDate) {
      return localDateToDate(segment.arrivalLocalDate);
    }
    if (segment.arrivalTime && segment.arrival?.timeZone) {
      return timestampToDate(segment.arrivalTime, segment.arrival?.timeZone);
    }
    return undefined;
  },

  departureDateForSegment: () => (segment: Segment) => {
    if (segment.departureLocalDate) {
      return localDateToDate(segment.departureLocalDate);
    }
    if (segment.departureTime && segment.departure?.timeZone) {
      return timestampToDate(
        segment.departureTime,
        segment.departure?.timeZone
      );
    }
    return undefined;
  },

  localDepartureTimeForSegment: (_, getters) => (segment: Segment) => {
    getters.formatTimestamp(
      segment.departureTime,
      segment.departure?.timeZone,
      timeFormatting
    );
  },

  localArrivalTimeForSegment: (_, getters) => (segment: Segment) => {
    getters.formatTimestamp(
      segment.arrivalTime,
      segment.arrival?.timeZone,
      timeFormatting
    );
  },

  requestForInterimTimingOutOfSync: (state, getters) => interim => {
    // Arrival
    const arrivalFn = (getters.arrivalDateForInterim as unknown) as (
      interim: Interim
    ) => Date | undefined;
    const arrivalDate = arrivalFn(interim);
    const arrivalDateRequest = getUserRequestsArrivalDate(
      unMaybeArray(interim.preferences)
    );
    const arrivalDateOutOfSync =
      arrivalDateRequest !== undefined &&
      arrivalDateRequest.date !== arrivalDate;

    // Departure
    const departureFn = (getters.departureDateForInterim as unknown) as (
      interim: Interim
    ) => Date | undefined;
    const departureDate = departureFn(interim);
    const departureDateRequest = getUserRequestsDepartureDate(
      unMaybeArray(interim.preferences)
    );
    const departureDateOutOfSync =
      departureDateRequest !== undefined &&
      departureDateRequest.date !== departureDate;

    // Duration
    const durationFn = (getters.durationForInterim as unknown) as (
      interim: Interim
    ) => number | undefined;
    const duration = durationFn(interim);
    const durationMinRequest = getUserRequestsMinDaysInVisit(
      unMaybeArray(interim.preferences)
    );
    const durationMinRequestOutOfSync =
      durationMinRequest !== undefined &&
      durationMinRequest.minDays !== duration;

    return (
      arrivalDateOutOfSync ||
      departureDateOutOfSync ||
      durationMinRequestOutOfSync
    );
  },

  requestForTimingOutOfSync: (state, getters) => {
    const outOfSyncFn = (getters.requestForInterimTimingOutOfSync as unknown) as (
      interim: Interim
    ) => boolean;

    return state.itinerary
      ? unMaybeArray(state.itinerary.interims).some(i => outOfSyncFn(i))
      : false;
  },
};
