import { createApp } from 'vue';
import { store } from './store';
import App from './App.vue';
import router from './router';
import config from 'config';

// Mapbox
import 'mapbox-gl/dist/mapbox-gl.css';

// tooltip styling
import 'v-tooltip/dist/v-tooltip.css';

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome';

// Solid
import {
  faCircleUser,
  faAngleRight,
  faAngleDown,
  faCheck,
  faUndoAlt,
  faTriangleExclamation,
  faExclamationCircle,
  faCircle,
  faPlane,
  faAngleUp,
  faLocationPin,
  faPennant,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faCheckCircle,
  faMessage,
  faTableList,
  faGlobe,
  faBadgeCheck,
  faCircleInfo,
  faBullseyeArrow,
  faCarSide,
} from '@fortawesome/pro-solid-svg-icons';
// @ts-ignore
library.add(
  faCircleUser,
  faAngleRight,
  faAngleDown,
  faCheck,
  faUndoAlt,
  faTriangleExclamation,
  faExclamationCircle,
  faCircle,
  faPlane,
  faAngleUp,
  faLocationPin,
  faPennant,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faCheckCircle,
  faMessage,
  faTableList,
  faGlobe,
  faBadgeCheck,
  faCircleInfo,
  faBullseyeArrow,
  faCarSide
);

// regular
import {
  faGripLines,
  faTimes as farTimes,
  faLightbulb as farLightbulb,
  faCircle as farCircle,
  faCalendar as farCalendar,
  faClock,
  faAngleDown as farAngleDown,
  faAt,
  faXmark as farXmark,
} from '@fortawesome/pro-regular-svg-icons';
library.add(
  faGripLines,
  farTimes,
  farLightbulb,
  farCircle,
  farCalendar,
  faClock,
  farAngleDown,
  faAt,
  farXmark
);

// Light
import {
  faAngleRight as falAngleRight,
  faAngleLeft as falAngleLeft,
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faCheck as falCheck,
  faCircleUser as falCircleUser,
  faTimesCircle,
  faQuestion,
  faLightbulb,
  faCalendar,
  faPaperPlaneTop,
  faSpinnerThird,
  faCopy,
  faTrashCan,
  faCalendarMinus,
  faArrowDown,
  faXmark as falXmark,
} from '@fortawesome/pro-light-svg-icons';
library.add(
  falAngleRight,
  falAngleLeft,
  falAngleUp,
  falAngleDown,
  falCheck,
  falCircleUser,
  faTimesCircle,
  faQuestion,
  faLightbulb,
  faCalendar,
  faPaperPlaneTop,
  faSpinnerThird,
  faCopy,
  faTrashCan,
  faCalendarMinus,
  faArrowDown,
  falXmark
);

// Thin
import { faBug } from '@fortawesome/pro-thin-svg-icons';
library.add(faBug);

// Tailwind
import 'tailwindcss/tailwind.css';

// v-click-outside
import vClickOutside from './click-outside';

// v-calendar
import { Calendar, DatePicker } from 'v-calendar';

import { ObserveVisibility } from 'vue-observe-visibility';

// vue-cookies
import { VueCookieNext } from 'vue-cookie-next';

// @ts-ignore
const app = createApp(App);
app.config.globalProperties = {};
app.use(store);
app.use(router);

// Sliding
import SlideUpDown from 'vue3-slide-up-down';
app.component('slide-up-down', SlideUpDown);

// TODO: replace with ant
// v-tooltip
import VTooltipPlugin from 'v-tooltip';
app.use(VTooltipPlugin, {
  themes: {
    WarningTooltip: {
      $extend: 'tooltip',
    },
    ErrorTooltip: {
      $extend: 'tooltip',
    },
  },
});

// Ant design
import { Tooltip } from 'ant-design-vue';
import 'ant-design-vue/lib/tooltip/style/index.css';
app.use(Tooltip);

import { Switch } from 'ant-design-vue';
import 'ant-design-vue/lib/switch/style/index.css';
app.use(Switch);

import { Popover } from 'ant-design-vue';
import 'ant-design-vue/lib/popover/style/index.css';
app.use(Popover);

import { Modal } from 'ant-design-vue';
import 'ant-design-vue/lib/modal/style/index.css';
app.use(Modal);

import { message } from 'ant-design-vue';
import 'ant-design-vue/lib/message/style/index.css';
message.config({ duration: 5 });

// Vue cookie
app.use(VueCookieNext);
VueCookieNext.config({ expire: '7d' });

// Google Analytics
import VueGtag from 'vue-gtag';
if (
  config.GOOGLE_ANALYTICS_MEASUREMENT_ID &&
  config.GOOGLE_ANALYTICS_MEASUREMENT_ID.length > 0
) {
  app.use(VueGtag, { config: { id: config.GOOGLE_ANALYTICS_MEASUREMENT_ID } });
}

// Directives
app.directive('clickOutside', vClickOutside);
// @ts-ignore
app.directive('observe-visibility', ObserveVisibility);

// Components
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('font-awesome-layers', FontAwesomeLayers);
app.component('Calendar', Calendar);
app.component('DatePicker', DatePicker);
app.mount('#app');

//
// Apply final styles to ensure we override anything previous

// ant styles
import '@/styles/ant.scss';

// global styling
import './styles/app.scss';
