import { State } from '@/store';
import {
  Carrier,
  CitiesPhase,
  City,
  Concept,
  DatesPhase,
  Formula,
  Interim,
  localDateToDate,
  Maybe,
  Phase,
  Segment,
  unMaybe,
  unMaybeArray,
} from '@/api/service';
import { GetterTree } from 'vuex';
import { conceptToString, toVisit } from '@/api/kr';
import uniqueBy from '@popperjs/core/lib/utils/uniqueBy';

export interface Getters {
  segments: (state: State) => Segment[];
  firstSegment: (state: State, getters: Getters) => Segment | undefined;
  lastSegment: (state: State, getters: Getters) => Segment | undefined;
  segmentForInterim: (
    state: State,
    getters: Getters
  ) => (interim: Interim) => Segment | undefined;
  tripStartDate: (state: State) => Date | undefined;
  isOriginCity: (state: State, getters: Getters) => (city: City) => boolean;
  originCity: (state: State) => City | undefined;
  isOriginVisit: (
    state: State,
    getters: Getters
  ) => (visit: Concept) => boolean;
  originVisit: (state: State, getters: Getters) => Concept | undefined;
  departurePreferences: (state: State) => Formula[];
  isFinalCity: (state: State, getters: Getters) => (city: City) => boolean;
  finalCity: (state: State) => City | undefined;
  isFinalVisit: (state: State, getters: Getters) => (visit: Concept) => boolean;
  finalVisit: (state: State, getters: Getters) => Concept | undefined;
  arrivalPreferences: (state: State) => Formula[];
  cities: (state: State) => City[];
  cityFromVisit: (
    state: State
  ) => (visit: Formula | undefined) => City | undefined;
  interims: (state: State) => Interim[];
  carriers: (state: State) => Carrier[];
  itineraryOrdered: (state: State, getters: Getters) => boolean;
  currentPhase: (state: State, getters: Getters) => Phase | undefined;
  isPhaseCitiesBatching: (state: State, getters: Getters) => boolean;
  isPhaseCitiesOrderValid: (state: State, getters: Getters) => boolean;
  isPhaseDatesBatching: (state: State, getters: Getters) => boolean;
  haveCities: (state: State, getters: Getters) => boolean;
  haveOrigin: (state: State) => boolean;
  haveInterims: (state: State) => boolean;
  haveFinal: (state: State) => boolean;
  haveDates: (state: State, getters: Getters) => boolean;
  haveFlights: (state: State, getters: Getters) => boolean;
  interimForSegmentIndex: (
    state: State,
    getters: Getters
  ) => (index: number) => Interim | undefined;
  interimForCity: (
    state: State,
    getters: Getters
  ) => (city: City) => Interim | undefined;
  isGroundSegment: (state: State) => (segment: Segment) => boolean;
}

export const getters: GetterTree<State, State> & Getters = {
  segments: state =>
    state.itinerary && state.itinerary.segments != null
      ? state.itinerary.segments.filter(
          (s: Maybe<Segment>): s is Segment => s != null
        )
      : [],
  firstSegment: (_, getters) => {
    const segments = (getters.segments as unknown) as Segment[];
    return segments.length > 0 ? segments[0] : undefined;
  },
  lastSegment: (_, getters) => {
    const segments = (getters.segments as unknown) as Segment[];
    return segments.length > 0 ? segments[segments.length - 1] : undefined;
  },
  segmentForInterim: (_, getters) => interim => {
    const segments = (getters.segments as unknown) as Segment[];
    return segments.find(
      s => conceptToString(s.departureVisit) === conceptToString(interim.visit)
    );
  },

  tripStartDate: state => {
    const firstSegment = state.itinerary?.segments
      ? state.itinerary?.segments[0]
      : null;
    if (firstSegment) {
      return localDateToDate(unMaybe(firstSegment.departureLocalDate));
    }
  },

  // Origin
  isOriginCity: (_, getters) => city => {
    // TODO: solve typescript issue with getters
    const o = getters.originCity as City | undefined;
    return o !== undefined && city != null ? o == city : false;
  },
  originCity: state =>
    state.itinerary?.origin != null ? state.itinerary?.origin : undefined,
  isOriginVisit: (_, getters) => visit => {
    // TODO: solve typescript issue with getters
    const o = getters.originVisit as Concept | undefined;
    // TODO hacky comparison for unordered itinerary map comparison
    return o !== undefined
      ? conceptToString(o) == conceptToString(visit)
      : false;
  },
  originVisit: state =>
    state.itinerary?.originVisit != null
      ? state.itinerary?.originVisit
      : undefined,
  departurePreferences: state => {
    return unMaybeArray(state.itinerary?.departurePreferences);
  },

  // Final
  isFinalCity: (_, getters) => city => {
    // TODO: solve typescript issue with getters
    const v = getters.finalCity as City | undefined;
    return v !== undefined && city.code != null ? v.code === city.code : false;
  },
  finalCity: state =>
    state.itinerary?.final != null ? state.itinerary?.final : undefined,
  isFinalVisit: (_, getters) => visit => {
    // TODO: solve typescript issue with getters
    const v = getters.finalVisit as Concept | undefined;
    return v !== undefined ? v == visit : false;
  },
  finalVisit: state =>
    state.itinerary?.finalVisit != null
      ? state.itinerary?.finalVisit
      : undefined,
  arrivalPreferences: state => {
    return unMaybeArray(state.itinerary?.arrivalPreferences);
  },

  cities: state => {
    const cities: City[] = [];
    if (state.itinerary != null && state.itinerary.origin != null) {
      cities.push(state.itinerary.origin);
    }
    if (state.itinerary && state.itinerary?.interims != null)
      state.itinerary.interims
        .filter((i: unknown): i is Interim => i != null)
        .forEach((i: Interim) => {
          if (i.city != null) {
            cities.push(i.city);
          }
        });
    if (state.itinerary != null && state.itinerary.final != null) {
      cities.push(state.itinerary.final);
    }
    return uniqueBy(cities, c => c.code);
  },

  // WARN: this code assumes a city-code -> city entity mapping - i.e. city/<CODE> is the entity.
  cityFromVisit: state => visitFormula => {
    const visit = toVisit(visitFormula);
    return visit !== undefined
      ? unMaybeArray(state.geographyData?.cities).find(
          c => 'city/' + c.code === visit.city
        )
      : undefined;
  },

  interims: state =>
    state.itinerary && state.itinerary?.interims != null
      ? state.itinerary.interims.filter(
          (i: Maybe<Interim>): i is Interim => i != null
        )
      : [],

  carriers: state => {
    const alphaRegex = /[^a-zA-Z ]/g;
    return state.geographyData && state.geographyData.carriers != null
      ? state.geographyData?.carriers
          ?.filter((c: Maybe<Carrier>): c is Carrier => c !== undefined)
          .filter((c: Carrier) => !c.code?.match(alphaRegex))
      : [];
  },

  // Check if sequence on segments is >= 0
  itineraryOrdered: state => {
    return (
      state.itinerary != null &&
      state.itinerary.segments != null &&
      state.itinerary.segments?.every(
        s => s?.sequence != null && s?.sequence >= 0
      )
    );
  },

  currentPhase: state => {
    return unMaybe(state.itinerary?.phase);
  },

  isPhaseCitiesBatching: state => {
    return (
      state.itinerary?.phase?.cities != null &&
      state.itinerary.phase.cities === CitiesPhase.CitiesPhaseInterimsBatching
    );
  },

  isPhaseCitiesOrderValid: state => {
    return (
        state.itinerary?.phase?.cities != null &&
        state.itinerary.phase.cities === CitiesPhase.CitiesPhaseOrderValid
    );
  },

  isPhaseDatesBatching: state => {
    return (
      state.itinerary?.phase?.dates != null &&
      state.itinerary.phase.dates === DatesPhase.DatesPhaseVisitDatesBatching
    );
  },

  haveCities: (state, getters) => {
    const haveInterims = (getters.haveInterims as unknown) as boolean;
    return (
      state.itinerary != null &&
      (state.itinerary.origin != null || haveInterims)
    );
  },

  haveOrigin: state => {
    return state.itinerary != null && state.itinerary.origin != null;
  },

  haveInterims: state => {
    return unMaybeArray(state.itinerary?.interims).length > 0;
  },

  haveFinal: state => {
    return state.itinerary != null && state.itinerary.final != null;
  },

  // Check if sequence on segments is >= 0
  haveDates: (state, getters) => {
    const isGroundSegmentFn = (getters.isGroundSegment as unknown) as (
      segment: Segment
    ) => boolean;

    return (
      state.itinerary != null &&
      state.itinerary.segments != null &&
      state.itinerary.segments
        .filter((s: unknown): s is Segment => s != null)
        .every(
          segment =>
            isGroundSegmentFn(segment) ||
            // Dates but without flights
            (segment.departureLocalDate != null &&
              segment.arrivalLocalDate != null) ||
            // With flights
            (segment.departureTime != null && segment.arrivalTime != null)
        )
    );
  },

  // Have at least one flight on a segment
  haveFlights: (_, getters) => {
    const segments = (getters.segments as unknown) as Segment[];
    return (
      segments.length > 0 &&
      segments.some(
        s =>
          s.flightSegment != null &&
          s.flightSegment.flightOptions != null &&
          s.flightSegment.flightOptions.length > 0
      )
    );
  },

  interimForSegmentIndex: (_, getters) => segmentIndex => {
    // TODO: solve typescript issue with getters
    const interims = (getters.interims as unknown) as Interim[];
    return segmentIndex > 0 ? interims[segmentIndex - 1] : undefined;
  },

  interimForCity: (_, getters) => city => {
    // TODO: solve typescript issue with getters
    const interims = (getters.interims as unknown) as Interim[];
    return interims.find(
      (interim): interim is Interim => interim?.city?.code === city.code
    );
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isGroundSegment: _ => segment => segment.groundSegment != null,
};
