import Logger, { LOG_LEVEL } from '@/logger';
const logger = new Logger('rtw:store');

import {
  Store as VuexStore,
  createStore,
  CommitOptions,
  DispatchOptions,
  createLogger,
} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

import { Getters, getters } from './getters';
import { Mutations, mutations } from './mutations';
import { Actions, actions } from './actions';
import { Client } from 'graphql-ws';

import config from 'config';

// Grab some config from params --- WARN: ONLY LOCAL TO THIS FILE
const params = new URLSearchParams(window.location.search.substring(1));
const DEBUG: boolean = params.get('DEBUG') === 'true' || config.DEBUG;
const CONFIG_LOG_LEVEL: string =
  config.LOG_LEVEL != undefined ? config.LOG_LEVEL : 'ERROR';

export const INITIAL_RETRY_WAIT_MS = 5000;
export const RETRY_WAIT_BACKOFF = 1.3;

import {
  DialogBot,
  Itinerary,
  PriceSummary,
  SystemMessage,
  TravelInfo,
  UserMessage,
  GeographyResponse,
  Completion,
  Session,
  DialogStatus,
  SessionStatus,
  Tooltip,
  City,
  Choice,
  SeatClass,
  DialogStatusType,
  Segment,
} from '@/api/service';
import { LDClient } from 'launchdarkly-js-client-sdk';

import { AiEcApiDialogV1PropertyInput } from '@ec/rtw-graphql';

logger.debug('Endpoint: ' + config.ENDPOINT);
logger.debug('Data endpoint: ' + config.DATA_ENDPOINT);

export interface User {
  userId?: string;
  tenantId?: string;
  token?: string;
  firstName?: string;
  lastName?: string;
  zen3?: {
    loginUser?: Zen3LoginUser;
    socialLogin?: Zen3SocialLogin;
    userId: string;
    userKeyId: string;
  };
}

export interface Zen3Login {
  topic: 'login';
  isLoggedIn: 'true' | 'success';
  loginDetails: {
    LoginUser?: Zen3LoginUser;
    SocialLogin?: Zen3SocialLogin;
  };
  token: string;
  userKeyId: string;
}

export interface Zen3LoginUser {
  Email: string;
  EmailSubscribedFlag: boolean;
  FirstName: string;
  LastName: string;
  login: boolean;
  Token: string;
  UserId: string;
  userKeyId: string;
}

export interface Zen3SocialLogin {
  Email: string;
  EmailSubscribedFlag: boolean;
  SessionToken: string;
  Status: 'success';
  UserId: string;
  FirstName: string;
  LastName: string;
  userKeyId: string;
}

export interface Category {
  catName: string;
  categoryThumbnail: string;
}

export interface CategoryItinerary {
  itinId: number;
  itinName: string;
  userId: string | null;
  userName: string | null;
  itinImage: string;
  itinContent: string;
  itinCategory: string;
  itinerary: string;
  date: string;
  featured: string;
  itineraryThumbnail: string;
  economyPrice: string | null;
  businessPrice: string | null;
  POSCurrencyCode: string | null;
}

export interface State {
  // Session
  debug: boolean;
  logLevel: string;
  errors: string[];
  warnings: string[];
  endpoint: string;
  client?: Client;
  dataEndpoint: string;
  ldClient?: LDClient;

  // Page access
  accessGranted: boolean;

  // User
  user: User;

  // Can have a sessionId but not yet loaded the session
  sessionId?: string;
  session?: Session;
  sessionStatus?: SessionStatus;
  isConnected: boolean;
  handleDisconnect: boolean;
  retryWaitMs: number;
  nextRetry?: Date;
  retryAttempts: number;
  errorMessage?: string;

  unsubscribers: { [key: string]: () => void | undefined };

  // itinerary
  linkId?: string;
  showItineraryList: boolean;
  itineraryList: unknown[];

  // onboarding
  showingOnboarding: boolean;
  allTooltips?: Tooltip[];
  matchingTooltips?: Tooltip[];

  // inspiration
  showInspiration: boolean;
  inspirationItineraryCities?: City[];

  // Dialog
  dialog: {
    bot?: DialogBot;
    status?: DialogStatus;
    messages: Array<UserMessage | SystemMessage>;
    waitingOnDialogUpdateAfterSend: boolean;
    properties: Array<AiEcApiDialogV1PropertyInput>;
    showHelp: boolean;
    showRepeatingSubText: boolean;
    inputBox: {
      text: string;
      selectedChoices: Choice[];
      completions: Completion[];
      lastInteraction: Date | undefined;
    };
  };
  geographyData?: GeographyResponse;
  itinerary?: Itinerary;
  showItinerary: boolean;
  hadDates: boolean;
  travelInfo?: TravelInfo;
  priceSummary?: PriceSummary;

  // AWS mturk task info
  // TODO: scope these variables
  taskId?: string;
  workerId?: string;
  experimentId?: string;
  assignmentId?: string;
  hitId?: string;
  turkSubmitTo?: string;

  domainId?: string;
  isComplete?: boolean;
  currentStopOverSegment?: Segment;
}

const state: State = {
  debug: DEBUG,
  logLevel: CONFIG_LOG_LEVEL,
  errors: [],
  warnings: [],
  endpoint: config.ENDPOINT,
  dataEndpoint: config.DATA_ENDPOINT,
  client: undefined,
  ldClient: undefined,

  sessionId: undefined,
  session: undefined,
  sessionStatus: undefined,
  isConnected: true, // Assume connected to start
  handleDisconnect: true,
  retryWaitMs: INITIAL_RETRY_WAIT_MS,
  nextRetry: undefined,
  retryAttempts: 0,

  // Access
  accessGranted: false,

  // User
  user: {
    userId: 'anonymous',
    tenantId: 'rtw',
    token: undefined,
    firstName: undefined,
    lastName: undefined,
    zen3: undefined,
  },

  errorMessage: undefined,
  unsubscribers: {},

  // Itineraries
  linkId: undefined,
  showItineraryList: false,
  itineraryList: [],

  showingOnboarding: false,
  allTooltips: [],
  matchingTooltips: [],

  // inspiration
  showInspiration: false,
  inspirationItineraryCities: [],

  dialog: {
    bot: undefined,
    status: {
      // Default to working initially, as bot waiting on being setup
      type: DialogStatusType.StatusTypeWorking,
    },
    messages: [],
    properties: [],
    waitingOnDialogUpdateAfterSend: false,
    showHelp: false,
    showRepeatingSubText: false,
    inputBox: {
      completions: [],
      text: '',
      selectedChoices: [],
      lastInteraction: undefined,
    },
  },

  geographyData: undefined,
  itinerary: undefined,
  showItinerary: true,
  hadDates: false,

  travelInfo: {
    numAdults: 1,
    numChildren: 0,
    preferredCarrier: undefined,
    seatClass: SeatClass.SeatClassEconomy,
  },

  priceSummary: undefined,
  taskId: undefined,
  workerId: undefined,
  experimentId: undefined,
  assignmentId: undefined,
  hitId: undefined,
  turkSubmitTo: undefined,
  currentStopOverSegment: undefined,
};

// Build list of plugins
const plugins = [
  createPersistedState({
    paths: ['accessGranted'],
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) =>
        Cookies.set(key, value, {
          expires: 7,
          secure: config.ENDPOINT.indexOf('localhost') !== 0,
        }),
      removeItem: key => Cookies.remove(key),
    },
  }),
];

// Vuex debug logging
if (CONFIG_LOG_LEVEL === LOG_LEVEL.DEBUG) {
  plugins.push(createLogger());
}

export const store = createStore<State>({
  state,
  getters,
  mutations,
  actions,
  plugins,
});

export type Store<S = State> = Omit<
  VuexStore<S>,
  'commit' | 'getters' | 'dispatch'
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload?: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
};

export function useStore() {
  return store as Store;
}
