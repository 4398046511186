export interface PartnerCarrier {
  code?: string;
  name?: string;
  logo?: string;
}

export const carrierCodeToName: Record<string, string> = {
  AA: 'American Airlines',
  AS: 'Alaska Airlines',
  BA: 'British Airways',
  CX: 'Cathay Pacific',
  AY: 'Finnair',
  IB: 'Iberia',
  JL: 'Japan Airlines',
  MH: 'Malaysia Airlines',
  QF: 'Qantas',
  QR: 'Qatar Airways',
  AT: 'Royal Air Moroc',
  RJ: 'Royal Jordanian',
  S7: 'S7 Airlines',
  UL: 'Sri Lankan Airlines',
  JC: 'JAL Express',
  NU: 'Japan Transocean Air',
};

export const partnerCarrierCodes = [
  'AS',
  'AA',
  'BA',
  'CX',
  'AY',
  'IB',
  'JL',
  'MH',
  'QF',
  'QR',
  'AT',
  'RJ',
  //'S7', - removed as partner carrier
  'UL',
];
