import { Getters as BaseGetters, getters as baseGetters } from './getters/base';
import {
  Getters as SessionGetters,
  getters as sessionGetters,
} from './getters/session';
import {
  Getters as DialogGetters,
  getters as dialogGetters,
} from './getters/dialog';
import {
  Getters as ItineraryGetters,
  getters as itineraryGetters,
} from './getters/itinerary';
import {
  Getters as DateTimeGetters,
  getters as dateTimeGetters,
} from './getters/datetime';
import { GetterTree } from 'vuex';
import { State } from '@/store';

export type Getters = BaseGetters &
  SessionGetters &
  DialogGetters &
  ItineraryGetters &
  DateTimeGetters;

// NOTE: The hierarchy of these getters can matter if one wants to reference another.
//       eg: if metadata from session getters needs to be referenced elsewhere.

export const getters: GetterTree<State, State> & Getters = {
  ...baseGetters,
  ...sessionGetters,
  ...dialogGetters,
  ...itineraryGetters,
  ...dateTimeGetters,
};
